import React from 'react'
import {Input, Modal, Row, Col, Form, FormGroup, Label} from "reactstrap";
import Select from "react-select"
import DropImage from "../../components/DropImage"
import { Link } from "react-router-dom"
import Switch from "react-switch"

const render_rows = (tog_standard, modal_standard, product, onChangeInput, updateCurrentProduct, categories, sizes, colors, providers, handleAcceptedFiles, deleteFiles, prod_imgs) => {
    if(!product) return "";

    const { name, code, sku, price, provider_price, category_id, size_id, color_id, provider_id, description, images_url, in_woocommerce } = product;

    const category = categories.find(x => x.value === category_id);
    const size = sizes.find(x => x.value === size_id);
    const color = colors.find(x => x.value === color_id);
    const provider = providers.find(x => x.value === provider_id);
    const Offsymbol = () => {
        return (
          <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
          >
              {" "}
          </div>
        )
    }
    const OnSymbol = props => {
        return (
          <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
          >
              {" "}
          </div>
        )
    }

    return (
      <Modal
        size="xl"
        isOpen={modal_standard}
        toggle={() => tog_standard('update')}
      >
          <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                  Actualizar producto
              </h5>
              <button type="button"
                      onClick={() => tog_standard('update')}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
              <Form>
                  <Row>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="bundle_qty">Nombre</Label>
                              <Input type="text" className="form-control" defaultValue={ name } onChange={ e => {onChangeInput(e.target.value, 'name')} } />
                          </FormGroup>
                      </Col>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="bundle_qty">Codigo</Label>
                              <Input type="text" className="form-control" defaultValue={ code } onChange={ e => {onChangeInput(e.target.value, 'code')} } />
                          </FormGroup>
                      </Col>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="bundle_qty">SKU</Label>
                              <Input type="text" className="form-control" defaultValue={ sku } onChange={ e => {onChangeInput(e.target.value, 'sku')} } />
                          </FormGroup>
                      </Col>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="brand">Proveedor</Label>
                              <Select
                                classNamePrefix="select1-selection"
                                onChange={ provider => {onChangeInput( provider, 'provider')} }
                                options={ providers }
                                defaultValue={ provider }
                              />
                          </FormGroup>
                      </Col>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="bundle_qty">Precio</Label>
                              <Input type="number" className="form-control" defaultValue={ price } onChange={ e => {onChangeInput(Number(e.target.value), 'price')} } />
                          </FormGroup>
                      </Col>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="provider_price">Precio del Proveedor</Label>
                              <Input type="number" className="form-control" value={provider_price} onChange={ e => {onChangeInput(Number(e.target.value), 'provider_price')} } />
                          </FormGroup>
                      </Col>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="weight">Categoria</Label>
                              <Select
                                classNamePrefix="select1-selection"
                                onChange={ category => {onChangeInput( category, 'category')} }
                                options={ categories }
                                defaultValue={ category }
                              />
                          </FormGroup>
                      </Col>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="size">Tamaño</Label>
                              <Select
                                classNamePrefix="select1-selection"
                                onChange={ size => {onChangeInput( size, 'size')} }
                                options={ sizes }
                                defaultValue={ size }
                              />
                          </FormGroup>
                      </Col>
                      <Col sm="4">
                          <FormGroup>
                              <Label htmlFor="color">Color</Label>
                              <Select
                                classNamePrefix="select1-selection"
                                onChange={ color => {onChangeInput( color, 'color')} }
                                options={ colors }
                                defaultValue={ color }
                              />
                          </FormGroup>
                      </Col>
                      <Col sm="12 mt-3">
                          <div className="mb-3 row">
                              <label className="col-md-2 col-form-label">
                                  WooCommerce
                              </label>
                              <div className="col-md-10">
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="me-1 mb-sm-8 mt-1"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={ e => {onChangeInput(!in_woocommerce, 'in_woocommerce')} }
                                    checked={in_woocommerce}
                                  />
                              </div>
                          </div>
                      </Col>
                      <Col sm="12">
                          <FormGroup>
                              <Label htmlFor="bundle_qty">Descripción</Label>
                              <textarea className="form-control" rows="2" style={{marginBottom: '10px'}} defaultValue={ description } onChange={ e => {onChangeInput(e.target.value, 'description')} } />
                          </FormGroup>
                      </Col>
                      <Col sm="2">
                          <Col sm="12" className="text-center">
                              <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(0) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
                          </Col>
                          <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 0) } drop_file_type={'Imágen principal'} images={ prod_imgs[0] } url={ images_url[0] } />
                      </Col>
                      <Col sum="2"/>
                      <Col sm="2">
                          <Col sm="12" className="text-center">
                              <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(1) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
                          </Col>
                          <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 1) } drop_file_type={'Imágen 1'} images={ prod_imgs[1] } url={ images_url[1] } />
                      </Col>
                      <Col sm="2">
                          <Col sm="12" className="text-center">
                              <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(2) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
                          </Col>
                          <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 2) } drop_file_type={'Imágen 2'} images={ prod_imgs[2] } url={ images_url[2] } />
                      </Col>
                      <Col sm="2">
                          <Col sm="12" className="text-center">
                              <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(3) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
                          </Col>
                          <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 3) } drop_file_type={'Imágen 3'} images={ prod_imgs[3] } url={ images_url[3] } />
                      </Col>
                      <Col sm="2">
                          <Col sm="12" className="text-center">
                              <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(4) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
                          </Col>
                          <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 4) } drop_file_type={'Imágen 4'} images={ prod_imgs[4] } url={ images_url[4] } />
                      </Col>
                  </Row>
              </Form>
          </div>
          <div className="modal-footer">
              <button
                type="button"
                onClick={() => tog_standard('update')}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                  Cancelar
              </button>
              <button
                type="button"
                onClick={updateCurrentProduct}
                className="btn btn-primary waves-effect waves-light"
                data-dismiss="modal"
              >
                  Actualizar
              </button>
          </div>
      </Modal>
    );
}

const ModalUpdateProduct = ({tog_standard, modal_standard, product, onChangeInput, updateCurrentProduct, categories, sizes, colors,  providers, handleAcceptedFiles, deleteFiles, prod_imgs}) => (
  render_rows(tog_standard, modal_standard, product, onChangeInput, updateCurrentProduct, categories, sizes, colors,  providers, handleAcceptedFiles, deleteFiles, prod_imgs)
)

export default ModalUpdateProduct
