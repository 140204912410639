import React from 'react'
import {Modal} from "reactstrap";

const render_rows = (tog_standard, modal_standard, product, deleteCurrentProduct) => {
    if(!product) return "";

    const { name } = product;

    return (
        <Modal
            size="xl"
            isOpen={modal_standard}
            toggle={() => tog_standard('delete')}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Eliminar { name }
                </h5>
                <button type="button"
                        onClick={() => tog_standard('delete')}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => tog_standard('delete')}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    onClick={deleteCurrentProduct}
                    className="btn btn-primary waves-effect waves-light"
                    data-dismiss="modal"
                >
                    Eliminar
                </button>
            </div>
        </Modal>
    );
}

const ModalDeleteProduct = ({tog_standard, modal_standard, product, deleteCurrentProduct}) => (
    render_rows(tog_standard, modal_standard, product, deleteCurrentProduct)
)

export default ModalDeleteProduct
