import React from 'react'
import {Card, CardBody, Tooltip} from "reactstrap";
import {Link} from "react-router-dom";

const render_rows = (categories, open_category_modal, delete_modal ) => {
    if(!categories) return "";

    const renderRow = (category) => (
        <tr key={"product_" + category.id}>
            <td style={{ whiteSpace: "normal" }}>
                {category.name}
            </td>
            <td style={{ whiteSpace: "normal" }}>
                {category.total_products}
            </td>
            <td style={{ whiteSpace: "normal" }}>
                <Link to="#" className="action-icon text-danger icon-margin" id={`TooltipTop_edit${category.id}`} onClick={() => open_category_modal(category)}> <i className="bx bx-edit font-size-22"/></Link>
                <Link to="#" className="action-icon text-danger icon-margin" id={`TooltipTop_delete${category.id}`} onClick={() => delete_modal(category)}> <i className="mdi mdi-trash-can font-size-22"/></Link>
            </td>
        </tr>
    )
    return categories.map(renderRow)
}
const CategoriesDatatable = ({ categories, open_category_modal, delete_modal }) => (
    <Card>
        <CardBody>
            <div className="table-responsive" style={{overflowY: 'hidden'}}>
                <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                    <tr>
                        <th>Categoria</th>
                        <th>Productos</th>
                        <th style={{ width: 100 }}/>
                    </tr>
                    </thead>
                    <tbody>
                    { render_rows(categories, open_category_modal, delete_modal) }
                    </tbody>
                </table>
            </div>
        </CardBody>
    </Card>
)

export default CategoriesDatatable
