import React from 'react'
import { Button, Card, CardBody } from "reactstrap"
import {Link} from "react-router-dom";
import logo from "../../assets/images/logo-full.jpg";
import moment from "moment";
import mainFunctions from "../../components/Functions"

const text_amount = (amount) => {
  return `$ ${(amount * 1).toFixed(2)}`
}
const product_url = (image_url) => {
  return image_url? image_url : logo;
}
const render_rows = (products, rollback) => {
    if(!products) return "";

  const renderRow = (product) => (
    <tr key={"product_" + product.id}>
      <td>
        <div
          className="img-background"
          style={{backgroundImage: `url(${product_url(product.image_url)})`}}
        />
      </td>
      <td style={{ whiteSpace: "normal" }}>
        <h5 className="font-size-14 text-truncate" style={{whiteSpace: 'normal'}}>{product.name}</h5>
        {product.sku && <p className="mb-0" style={{whiteSpace: 'normal'}}>SKU: <span className="font-weight-medium">{product.sku}</span></p>}
        <p className="mb-0" style={{whiteSpace: 'normal'}}>Código: <span className="font-weight-medium">{product.code}</span></p>
        {product.provider_name && <p className="mb-0" style={{whiteSpace: 'normal'}}>Proveedor: <span className="font-weight-medium">{product.provider_name}</span></p>}
      </td>
      <td style={{ whiteSpace: "normal" }}>
        {product.color && <p className="mb-0" style={{whiteSpace: 'normal'}}>Color: <span className="font-weight-medium">{product.color}</span></p>}
        {product.size && <p className="mb-0" style={{whiteSpace: 'normal'}}>Talle: <span className="font-weight-medium">{product.size}</span></p>}
        {product.category_name && <p className="mb-0" style={{whiteSpace: 'normal'}}>Categoría: <span className="font-weight-medium">{product.category_name}</span></p>}
        {product.brand_name && <p className="mb-0" style={{whiteSpace: 'normal'}}>Marca: <span className="font-weight-medium">{product.brand_name}</span></p>}
      </td>
      <td style={{ whiteSpace: "normal" }}>
        <h5 className="font-size-14 text-truncate">{text_amount(product.price)}</h5>
      </td>
      <td style={{ whiteSpace: "normal" }}>
        <h5 className="font-size-14 text-truncate" style={{whiteSpace: 'normal'}}>{moment(product.sold).format('DD/MM/YYYY HH:mm')}</h5>
      </td>
      <td style={{ whiteSpace: "normal" }}>
        <h5 className="font-size-14 text-truncate" style={{whiteSpace: 'normal'}}>{product.paid ? moment(product.paid).format('DD/MM/YYYY HH:mm'): 'NO'}</h5>
      </td>
      {
        mainFunctions.imAdmin() ?
          <td style={{ whiteSpace: "normal" }}>
            <Button color="primary" onClick={() => rollback(product)}>Devolver</Button>
          </td>
          :
          null
      }
    </tr>
  )
  return products.map(renderRow)
}
const SoldDatatable = ({ products, rollback }) => (
  <Card>
    <CardBody>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="thead-light">
          <tr>
            <th/>
            <th/>
            <th/>
            <th/>
            <th>Fecha de venta</th>
            <th>Fecha de pago</th>
            {
              mainFunctions.imAdmin() ?
                <th>Devolucion</th>
                :
                null
            }
          </tr>
          </thead>
          <tbody>
          { render_rows(products, rollback) }
          </tbody>
        </table>
      </div>
    </CardBody>
  </Card>
)

export default SoldDatatable
