import React from 'react'
import {Card, CardBody, Button} from "reactstrap";
import moment from "moment";

const text_amount = (amount) => {
  return `$ ${(amount * 1).toFixed(2)}`
}

const render_rows = (invoices, open_invoice_modal, delete_modal ) => {
  if(!invoices) return "";

  const renderRow = (invoice) => (
    <tr key={"product_" + invoice.id}>
      <td style={{ whiteSpace: "normal" }}>{ invoice.woo_invoice_id ? '#Woo' + invoice.woo_invoice_id : '#' + invoice.id }</td>
      <td style={{ whiteSpace: "normal" }}>{invoice.client_name}</td>
      <td> { moment(invoice.created_at).format('DD/MM/YYYY')} </td>
      <td style={{ whiteSpace: "normal" }}>{text_amount(invoice.amount)}</td>
      <td style={{ whiteSpace: "normal" }}>{invoice.shipping_title}</td>
      <td style={{ whiteSpace: "normal" }}>{invoice.payment_method_name}</td>
      <td style={{ whiteSpace: "normal" }}>{invoice.delivered ? 'Si' : 'No'}</td>
      <td style={{ whiteSpace: "normal" }}>
        <Button
          type="button"
          color="primary"
          size="sm"
          className="btn-rounded waves-effect waves-light"
          href={`orden/${invoice.id}`}
        >
          Detalles
        </Button>
      </td>
    </tr>
  )
  return invoices.map(renderRow)
}
const invoicesDatatable = ({ invoices }) => (
  <Card>
    <CardBody>
      <div className="table-responsive" style={{overflowY: 'hidden'}}>
        <table className="table table-centered table-nowrap mb-0">
          <thead className="thead-light">
          <tr>
            <th>Orden ID</th>
            <th>Cliente</th>
            <th>Fecha</th>
            <th>Monto</th>
            <th>Shipping</th>
            <th>Método de pago</th>
            <th>Entregado</th>
            <th>Detalles</th>
          </tr>
          </thead>
          <tbody>
          { render_rows(invoices) }
          </tbody>
        </table>
      </div>
    </CardBody>
  </Card>
)

export default invoicesDatatable
