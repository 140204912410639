import React, { Component } from "react"
import {Container, Col, Row, Card, CardBody, Input} from "reactstrap"
import {Link} from "react-router-dom";
import ModalCreate from "./modalCreate"
import {apiPaths} from "../../config/constants";
import mainFunctions from "../../components/Functions";
import Request from "superagent";
import UsersDatatable from "./datatable"
import toastr from 'toastr'
import Pages from "../../components/Pages"
import ModalUpdateProvider from "./ModalUpdateProvider"
import ModalDeleteProvider from "./ModalDeleteProvider"

class Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      page_size: 50,
      total_rows: 0,
      q: '',
    }

    this.new_provider_name_ref = React.createRef();
    this.new_provider_email_ref = React.createRef();
    this.new_provider_password_ref = React.createRef();
    this.new_note_ref = React.createRef();
  }
  componentDidMount() {
    this.providers();
  }
  providers = () => {
    setTimeout(function() {
      const { page, page_size, q } = this.state;
      let query = { page: page, pageSize: page_size, q: q }

      Request
          .get(apiPaths.providers + mainFunctions.formattedGet(query, false))
          .set('Accept', 'application/json')
          .set(mainFunctions.headers())
          .then((response) => {
            if (response.body && response.body.data) {
              const {providers, total_rows} = response.body.data;
              this.setState({
                providers: providers,
                total_rows: total_rows,
              })
            }
          }).catch((error) => {
        this.setState({
          errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
        })
      });
    }.bind(this), 20);
  };
  togCreateProvider = () => {
    this.setState({ modal_create_provider: !this.state.modal_create_provider });
  }
  createProvider = () => {
    const name = this.new_provider_name_ref.current.value;
    if(!name || name.length === 0 ) return;

    const email = this.new_provider_email_ref.current.value;
    if(!email || email.length < 10 ) return;

    const password = this.new_provider_password_ref.current.value;
    if(!password || password.length < 5 ) return;

    const note = this.new_note_ref.current.value;

    this.setState({ modal_create_provider: false });
    let post = { name: name, email: email, password: password, note: note };
    toastr.options = mainFunctions.toastrOptions();

    Request
        .post(apiPaths.providers)
        .set('Accept', 'application/json')
        .set(mainFunctions.headers())
        .send(post)
        .then((response) => {
          if(response.status >= 200 && response.status <= 299) {
            const {providers} = response.body.data;
            mainFunctions.setProviders(providers);
            toastr["success"]("Creada satisfactoriamente", "Categoria");
            this.providers();
          } else {
            toastr["warning"]("Error inesperado", "Categoria");
          }

        }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  showProviderModal = (provider) =>{
    this.setState({ current_provider: provider });
    this.tog_standard();
  };
  tog_standard = () => {
    this.setState({ modal_standard: !this.state.modal_standard });
  };
  deleteProviderModal = (provider) =>{
    this.setState({ current_provider: provider });
    this.tog_delete();
  };
  tog_delete = () => {
    this.setState({ modal_delete: !this.state.modal_delete });
  };
  setPage = (value) => {
    this.setState({ page: value });
    this.providers();
  };
  updateCurrentProviderInput = (value, attr) => {
    const {current_provider} = this.state;

    switch (attr) {
      case 'name':
        current_provider.name = value;
        break;
      case 'email':
        current_provider.email = value;
        break;
      case 'password':
        current_provider.password = value;
        break;
      case 'initial':
        current_provider.initial = value;
        break;
      case 'note':
        current_provider.note = value;
        break;
      default:

    }
  }

  updateCurrentProvider = () => {
    const { current_provider } = this.state;
    const { id, name, email, password, note } = current_provider;

    let provider = { name: name, email: email, note: note };
    if(password && password.length > 0) provider.password = password;

    this.setState({ modal_create_provider: false });
    toastr.options = mainFunctions.toastrOptions();
    Request
        .put(`${apiPaths.providers}/${id}`)
        .set('Accept', 'application/json')
        .set(mainFunctions.headers())
        .send(provider)
        .then((response) => {
          if(response.status >= 200 && response.status <= 299) {
            const {providers} = response.body.data;
            mainFunctions.setProviders(providers);
            this.setState({ modal_standard: false });
            toastr["success"]("Actualizado satisfactoriamente", "Categoria");
            this.providers();
          } else {
            toastr["warning"]("Error inesperado", "Categoria");
          }
        }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  deleteCurrentProvider = () => {
    const { current_provider } = this.state;
    const { id } = current_provider;
    toastr.options = mainFunctions.toastrOptions();

    Request
        .delete(`${apiPaths.providers}/${id}`)
        .set('Accept', 'application/json')
        .set(mainFunctions.headers())
        .send({})
        .then((response) => {
          if(response.status >= 200 && response.status <= 299) {
            const {providers} = response.body.data;
            mainFunctions.setProviders(providers);
            this.setState({ modal_delete: false });
            toastr["success"]("Eliminada satisfactoriamente", "Categoria");
            this.providers();
          } else {
            toastr["warning"]("Error inesperado", "Categoria");
          }
        }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  find = (event) => {
    event.preventDefault();
    this.setState({ q: event.target.value });
    this.providers();
  };
  render() {
    return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col xs="12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">Proveedores</h4>
                    <div className="page-title-right d-flex">
                      <div className="search-box mr-4" style={{marginRight: '10px'}}>
                      <div className="position-relative">
                        <Input
                            type="text"
                            className="form-control input-border"
                            placeholder="Buscar..."
                            value={this.state.q}
                            onChange={this.find}
                        />
                        <i className="bx bx-search-alt search-icon"/>
                      </div>
                    </div>
                      <i href="#" className="bx bx-plus-circle font-size-24 mr-2 provider-new" style={{ color: '#495057', marginTop: '7px'  }}  onClick={() => this.togCreateProvider()}/>
                      <ModalCreate
                          modal_standard={this.state.modal_create_provider}
                          tog_standard={() => this.togCreateProvider()}
                          new_provider_name_ref={this.new_provider_name_ref}
                          new_provider_email_ref={this.new_provider_email_ref}
                          new_provider_password_ref={this.new_provider_password_ref}
                          new_note_ref={this.new_note_ref}
                          createProvider={() => this.createProvider()}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <UsersDatatable
                          providers={this.state.providers}
                          open_provider_modal={(provider) => this.showProviderModal(provider)}
                          delete_modal={(provider) => this.deleteProviderModal(provider)}
                      />
                      <Pages
                          page={this.state.page}
                          page_size={this.state.page_size}
                          total_rows={this.state.total_rows}
                          onClickPage={value => this.setPage(value)}
                      />
                      <ModalUpdateProvider
                          modal_standard={this.state.modal_standard}
                          tog_standard={() => this.tog_standard()}
                          provider={this.state.current_provider}
                          onChangeInput={this.updateCurrentProviderInput}
                          updateCurrentProvider={this.updateCurrentProvider}
                      />
                      <ModalDeleteProvider
                          modal_standard={this.state.modal_delete}
                          tog_standard={() => this.tog_delete()}
                          provider={this.state.current_provider}
                          deleteCurrentProvider={this.deleteCurrentProvider}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
    )
  }
}

export default Provider;
