import React from 'react'
import './pages.scss';
import {Row, Col, Pagination, PaginationItem, PaginationLink} from "reactstrap";

const previous = ({disabled, index, onClickPage}) => {
    return <PaginationItem key={"_pgi_previous"}
                           disabled={disabled}
                           className={(disabled? "pointer-events": "")}
                           onClick={() => onClickPage(index)}>
        <PaginationLink previous />
    </PaginationItem>
}

const next = ({disabled, index, onClickPage}) => {
    return <PaginationItem key={"_pgi_next"}
                           disabled={disabled}
                           className={(disabled? "pointer-events": "")}
                           onClick={() => onClickPage(index)}>
        <PaginationLink next />
    </PaginationItem>
}

const render_items = ({page, page_size, total_rows, onClickPage}) => {
    if(page_size <= 0) page_size = 1
    if(total_rows > 0) total_rows -= 1

    let total_pages = parseInt(total_rows / page_size) + 1;

    let n_pages = []
    let left_page = page - 5
    let right_page = page + 5

    if(left_page <= 1) left_page = 1;
    if(right_page > total_pages) right_page = total_pages;

    for (let i = left_page; i <= right_page; i++) n_pages.push(i);

    const pages = []
    const items = n_pages.map((value, index) => (
      <PaginationItem key={"_pgi_" + index}
                      disabled={page === value}
                      className={(page === value? "pointer-events": "")}
                      onClick={() => onClickPage(value)}>
          <PaginationLink>
              {value}
          </PaginationLink>
      </PaginationItem>
    ));

    pages.push(previous({
        disabled: page === 1,
        index: page === 1 ? 1 : page - 1,
        onClickPage: onClickPage
    }));
    pages.push(items);
    pages.push(next({
        disabled: page === total_pages,
        index: page === total_pages ? total_pages : page + 1,
        onClickPage: onClickPage
    }));

    return pages;
}

const Pages = ({page, page_size, total_rows, onClickPage}) => (
  <Row>
      <Col lg="12">
          <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-2">
              {
                  render_items({page, page_size, total_rows, onClickPage})
              }
          </Pagination>
      </Col>
  </Row>
)

export default Pages
