import React from 'react'
import {Input, Modal, Row, Col, Form, FormGroup, Label} from "reactstrap";

const render_rows = (tog_standard, modal_standard, category, onChangeInput, updateCurrentCategory) => {
    if(!category) return "";

    const { name } = category;

    return (
        <Modal
            size="xl"
            isOpen={modal_standard}
            toggle={tog_standard}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Actualizar categoria { name }
                </h5>
                <button type="button"
                        onClick={tog_standard}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Form>
                    <Row>
                        <Col sm="4">
                            <FormGroup>
                                <Label htmlFor="name">Name</Label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    defaultValue={ name }
                                    onChange={ e => {onChangeInput(e.target.value)} }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={tog_standard}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancelar
                </button>
                <button
                    type="button"
                    onClick={updateCurrentCategory}
                    className="btn btn-primary waves-effect waves-light"
                    data-dismiss="modal"
                >
                    Actualizar
                </button>
            </div>
        </Modal>
    );
}

const ModalUpdateCategory = ({tog_standard, modal_standard, category, onChangeInput, updateCurrentCategory}) => (
    render_rows(tog_standard, modal_standard, category, onChangeInput, updateCurrentCategory)
)

export default ModalUpdateCategory
