import React from 'react'
import {Button, Col, Form, FormGroup, Input, InputGroup, Label, Modal, Row} from "reactstrap";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'
import './range_picker.scss';

const render_range_picker = ({ startDate, endDate, rangePickerChange }) => {
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    }

    return (
      <DateRangePicker
        ranges={ [selectionRange] }
        onChange={ rangePickerChange }
        locale={ es }
      />
    )
}

const RangePicker = ({ startDate, endDate, rangePickerChange, tog_standard, modal_standard }) => (
  <Modal
    isOpen={modal_standard}
    toggle={tog_standard}
  >
      <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
              Seleccione un rango de fechas
          </h5>
          <button type="button"
                  onClick={tog_standard}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
          >
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div className="modal-body text-center">
          {
              render_range_picker({ startDate, endDate, rangePickerChange })
          }
      </div>
  </Modal>
)

export default RangePicker
