import React from 'react';
import {CardBody, Form, FormGroup, Input, Label, Modal, Row, Col} from "reactstrap";
import DropImage from "../../components/DropImage";
import Select from "react-select";

const text_amount = (amount) => {
  return `$ ${(amount * 1).toFixed(2)}`
}

const render_rows = (tog_standard, modal_standard, onChangeInput, discount) => {

  return (
    <Modal
      isOpen={modal_standard}
      toggle={() => tog_standard('discount')}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Descuento
        </h5>
        <button type="button"
                onClick={() => tog_standard('discount')}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label htmlFor="bundle_qty">Monto del descuento</Label>
                <Input autoComplete="off" type="number" className="form-control" defaultValue={discount} onChange={ e => {onChangeInput(Number(e.target.value), 'discount')} }/>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => onChangeInput(0, 'discount_close')}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          style={{ backgroundColor: 'rgb(174,174,174)', borderColor: 'rgb(177,177,177)' }}
          onClick={() => tog_standard('discount')}
        >
          Aceptar
        </button>
      </div>
    </Modal>
  );
}

const ModalDiscount = ({tog_standard, modal_standard, onChangeInput, discount}) => (
  render_rows(tog_standard, modal_standard, onChangeInput, discount)
)

export default ModalDiscount
