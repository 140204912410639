import React, { Component } from "react"
import { Container, Col, Row, Card, CardBody, Input, Form, FormGroup, Label, Button } from "reactstrap"
import {Link} from "react-router-dom";
import ModalCreate from "./modalCreate"
import {apiPaths} from "../../config/constants";
import mainFunctions from "../../components/Functions";
import Request from "superagent";
import ProductsDatatable from "./datatable"
import toastr from 'toastr'
import Pages from "../../components/Pages"
import ModalUpdateProduct from "./ModalUpdateProduct"
import ModalDeleteProduct from "./ModalDeleteProduct"
import Select from "react-select"
import './products.scss';

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      page_size: 50,
      total_rows: 0,
      q: '',
      prod_imgs: [[],[],[],[],[]],
      new_product: {},
      brands: mainFunctions.getBrands(),
      categories: mainFunctions.getCCategories(),
      providers: mainFunctions.getProviders(),
      sizes: mainFunctions.getSizes(),
      colors: mainFunctions.getColors(),
      commission: 50,
      imAdmin: mainFunctions.imAdmin()
    }
  }
  componentDidMount() {
    this.products();
    this.providers();
    this.initNewProduct();
  }
  initNewProduct = () => {
    let product = {
      name: '',
      code: '',
      sku: '',
      price: 0,
      provider_price: 0,
      category_id: null,
      size_id: null,
      color_id: null,
      provider_id: null,
      description: '',
      images_url:[null, null, null, null, null],
      in_woocommerce: false
    }
    this.setState({new_product: product, prod_imgs: [[],[],[],[],[]]});
  }
  products = () => {
    setTimeout(function() {
      const { page, page_size, q, category_id, provider_id, size_id, color_id } = this.state;
      let query = { page: page, pageSize: page_size, q: q }
      if(category_id) query.category_id = category_id;
      if(size_id) query.size_id = size_id;
      if(color_id) query.color_id = color_id;
      if(provider_id) query.provider_id = provider_id;

      Request
        .get(apiPaths.products + mainFunctions.formattedGet(query, false))
        .set('Accept', 'application/json')
        .set(mainFunctions.headers())
        .then((response) => {
          if (response.body && response.body.data) {
            let { products, total_rows } = response.body.data;

            this.setState({
              products: products,
              total_rows: total_rows,
            })
          }
        }).catch((error) => {
        console.log('error products')
        window.location.assign("/login");
        this.setState({
          errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
        })
      });
    }.bind(this), 20);
  };
  providers = () => {
    if(!this.state.imAdmin) return;
    Request
      .post(`${apiPaths.providers}/all`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          let { providers } = response.body;
          mainFunctions.setProviders(providers);
          this.setState({ providers: mainFunctions.getProviders() });
        } else {
          toastr["warning"]("Error inesperado", "Categoria");
        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  };
  createProduct = () => {
    const { new_product } = this.state;
    const { name, code, sku, price, provider_price, category_id, size_id, color_id, description, provider_id, in_woocommerce } = new_product;
    let product = { name, code, sku, price, provider_price, provider_id, description, in_woocommerce };

    if(!provider_id) return;

    if(!!Number(category_id)) product.category_id = category_id;
    if(!!Number(size_id)) product.size_id = size_id;
    if(!!Number(color_id)) product.color_id = color_id;

    this.setState({ modal_create_product: false });
    toastr.options = mainFunctions.toastrOptions();

    Request
      .post(apiPaths.products)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send(this.getFilesData(product))
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          toastr["success"]("Creada satisfactoriamente", "Producto");
          this.products();
        } else {
          toastr["warning"]("Error inesperado", "Producto");
        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  getFilesData = (post) => {
    const { prod_imgs } = this.state;
    let formData = new FormData();

    for (let key in post) formData.append(key, post[key]);
    if (prod_imgs[0].length > 0) formData.append("image", prod_imgs[0][0]);
    if (prod_imgs[1].length > 0) formData.append("image1", prod_imgs[1][0]);
    if (prod_imgs[2].length > 0) formData.append("image2", prod_imgs[2][0]);
    if (prod_imgs[3].length > 0) formData.append("image3", prod_imgs[3][0]);
    if (prod_imgs[4].length > 0) formData.append("image4", prod_imgs[4][0]);

    return formData;
  };
  togStandard = (attr) => {
    switch(attr) {
      case 'create':
        this.initNewProduct();
        this.setState({ modal_create_product: !this.state.modal_create_product });
        break;
      case 'update':
        this.setState({ modal_standard: !this.state.modal_standard });
        break;
      case 'delete':
        this.setState({ modal_delete: !this.state.modal_delete });
        break;
      default:

    }
  };
  openModal = (product, attr) =>{
    this.setState({ current_product: product, prod_imgs: [[],[],[],[],[]] });
    this.togStandard(attr);
  }
  print = (product) =>{
    Request
      .put(`${apiPaths.products}/${product.id}/print`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          //
        } else {
          toastr["warning"]("Error inesperado", "Imprimir");
        }
      }).catch((error) => {
      console.log(error)
      toastr["warning"]("Error inesperado", "Imprimir");
    });
  }
  setPage = (value) => {
    this.setState({ page: value });
    this.products();
  };
  updateCurrentProductInput = (value, attr) => {
    let {current_product, commission} = this.state;

    switch(attr) {
      case 'name':
        current_product.name = value;
        break;
      case 'code':
        current_product.code = value;
        break;
      case 'sku':
        current_product.sku = value;
        break;
      case 'price':
        current_product.price = value;
        current_product.provider_price = value * commission / 100;
        break;
      case 'provider_price':
        current_product.provider_price = value;
        break;
      case 'category':
        current_product.category_id = value.value;
        break;
      case 'size':
        current_product.size_id = value.value;
        break;
      case 'color':
        current_product.color_id = value.value;
        break;
      case 'provider':
        current_product.provider_id = value.value;
        break;
      case 'description':
        current_product.description = value;
        break;
      case 'in_woocommerce':
        current_product.in_woocommerce = value;
        break;
      case 'delete_img':
        current_product.prod_img = null;
        current_product.image_url = null;
        break;
      default:

    }

    this.setState({current_product: current_product});
  };
  updateCurrentProduct = () => {
    const { current_product } = this.state;
    const { id, name, code, sku, price, provider_price, category_id, provider_id, size_id, color_id,  description, image_url, images_url, in_woocommerce } = current_product;
    let product = { name, code, sku, price, provider_price, provider_id, size_id, color_id, description, in_woocommerce };

    product.img_0 = images_url[0]
    product.img_1 = images_url[1]
    product.img_2 = images_url[2]
    product.img_3 = images_url[3]
    product.img_4 = images_url[4]

    product.category_id = !!Number(category_id) ? category_id : 0
    product.size_id = !!Number(size_id) ? size_id : 0
    product.color_id = !!Number(color_id) ? color_id : 0

    if(!image_url) product.delete_img = true
    toastr.options = mainFunctions.toastrOptions();
    this.setState({ modal_standard: false });

    Request
      .put(`${apiPaths.products}/${id}`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send(this.getFilesData(product))
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          toastr["success"]("Actualizado satisfactoriamente", "Producto");
          this.products();
        } else {
          toastr["warning"]("Error inesperado", "Producto");
        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  deleteCurrentProduct = () => {
    const { current_product } = this.state;
    const { id } = current_product;
    toastr.options = mainFunctions.toastrOptions();

    Request
      .delete(`${apiPaths.products}/${id}`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send({})
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          this.setState({ modal_delete: false });
          toastr["success"]("Eliminada satisfactoriamente", "Producto");
          this.products();
        } else {
          toastr["warning"]("Error inesperado", "Producto");
        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  handleAcceptedFiles = (files, index) => {
    const { prod_imgs } = this.state
    files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: this.formatBytes(file.size)
    }));

    prod_imgs[index] = files

    this.setState({ prod_imgs });
  }
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  createOnChangeInput = (value, attr) => {
    const { new_product, commission } = this.state;

    switch(attr) {
      case 'name':
        new_product.name = value;
        break;
      case 'code':
        new_product.code = value;
        break;
      case 'sku':
        new_product.sku = value;
        break;
      case 'price':
        new_product.price = value;
        new_product.provider_price = value * commission /100;
        break;
      case 'provider_price':
        new_product.provider_price = value;
        break;
      case 'category':
        new_product.category_id = value.value;
        break;
      case 'color':
        new_product.color_id = value.value;
        break;
      case 'size':
        new_product.size_id = value.value;
        break;
      case 'provider':
        new_product.provider_id = value.value;
        break;
      case 'description':
        new_product.description = value;
        break;
      case 'in_woocommerce':
        new_product.in_woocommerce = value;
        break;
      default:

    }

    this.setState({new_product: new_product});
  };
  handleUpdateFiles = (files, index = 6) => {
    const { prod_imgs, current_product } = this.state;
    files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));

    if(index === 0){
      prod_imgs[0] = files;
      current_product.images_url[0] = null;
    }else if(index === 1){
      prod_imgs[1] = files;
      current_product.images_url[1] = null;
    }else if(index === 2){
      prod_imgs[2] = files;
      current_product.images_url[2] = null;
    }else if(index === 3){
      prod_imgs[3] = files;
      current_product.images_url[3] = null;
    }else if(index === 4){
      prod_imgs[4] = files;
      current_product.images_url[4] = null;
    }

    this.setState({ current_product });
  }
  deleteFiles = (index) => {
    let { prod_imgs, current_product, new_product } = this.state;

    prod_imgs[index] = [];

    if(current_product) current_product.images_url[index] = null;
    if(new_product) new_product.images_url[index] = null;

    this.setState({ current_product, new_product, prod_imgs });
  }
  handleOptionsChange = (item, attr) => {
    switch(attr) {
      case 'category':
        this.setState({ category_id: item.value })
        break;
      case 'size':
        this.setState({ size_id: item.value })
        break;
      case 'color':
        this.setState({ color_id: item.value })
        break;
      case 'provider':
        this.setState({ provider_id: item.value })
        break;
      default:

    }

    this.setState({ page: 1 })
    this.products();
  }
  find = (event) => {
    event.preventDefault();
    this.setState({ q: event.target.value });
    this.products();
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row className="colMin600">
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">Productos</h4>
                  <div className="page-title-right d-flex">
                    <div className="search-box mr-4" style={{marginRight: '10px'}}>
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control input-border"
                          placeholder="Buscar..."
                          value={this.state.q}
                          onChange={this.find}
                        />
                        <i className="bx bx-search-alt search-icon"/>
                      </div>
                    </div>
                    <Select
                      classNamePrefix="select1-selection"
                      className="select_options"
                      placeholder="Categoria..."
                      options={this.state.categories}
                      onChange={(item) => this.handleOptionsChange(item, 'category')}
                    />
                    <Select
                      classNamePrefix="select1-selection"
                      className="select_options"
                      placeholder="Tamaño..."
                      options={this.state.sizes}
                      onChange={(item) => this.handleOptionsChange(item, 'size')}
                    />
                    <Select
                      classNamePrefix="select1-selection"
                      className="select_options"
                      placeholder="Color..."
                      options={this.state.colors}
                      onChange={(item) => this.handleOptionsChange(item, 'color')}
                    />
                    {
                      this.state.imAdmin ?
                        <Select
                          classNamePrefix="select1-selection"
                          className="select_options"
                          placeholder="Proveedor..."
                          options={this.state.providers}
                          onChange={(item) => this.handleOptionsChange(item, 'provider')}
                        />
                        :
                        ''
                    }
                    {
                      this.state.imAdmin ?
                        <i className="bx bx-plus-circle font-size-24 mr-2 product-new" style={{ color: '#495057', marginTop: '7px' }}  onClick={() => this.togStandard('create')}/>
                        :
                        ''
                    }
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3 colMax600">
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">Productos</h4>
                  <div className="page-title-right d-flex">
                    {
                      this.state.imAdmin ?
                        <Link href="#" className="bx bx-plus-circle font-size-24 mr-2 product-new" style={{ color: '#495057', marginTop: '7px' }}  onClick={() => this.togStandard('create')}/>
                        :
                        ''
                    }
                  </div>
                </div>
              </Col>
              <Col xs="12">
                <Form>
                  <Row>
                    <Col xs="12">
                      <FormGroup style={{marginTop: '15px'}}>
                        <div className="search-box mr-4" style={{marginRight: '10px'}}>
                          <div className="position-relative" style={{float: 'right', width: '200px'}}>
                            <Input
                              type="text"
                              className="form-control input-border"
                              placeholder="Buscar..."
                              value={this.state.q}
                              onChange={this.find}
                            />
                            <i className="bx bx-search-alt search-icon"/>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup style={{marginTop: '15px'}}>
                        <Select
                          classNamePrefix="select1-selection"
                          className="select_options select_options_xs"
                          placeholder="Marcas..."
                          options={this.state.brands}
                          onChange={(item) => this.handleOptionsChange(item, 'brand')}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup style={{marginTop: '15px'}}>
                        <Select
                          classNamePrefix="select1-selection"
                          className="select_options select_options_xs"
                          placeholder="Categorias..."
                          options={this.state.categories}
                          onChange={(item) => this.handleOptionsChange(item, 'category')}
                        />
                      </FormGroup>
                    </Col>
                    {
                      this.state.imAdmin ?
                        <Col xs="12">
                          <FormGroup style={{marginTop: '15px'}}>
                            <Select
                              classNamePrefix="select1-selection"
                              className="select_options select_options_xs"
                              placeholder="Proveedor..."
                              options={this.state.providers}
                              onChange={(item) => this.handleOptionsChange(item, 'provider')}
                            />
                          </FormGroup>
                        </Col>
                        :
                        ''
                    }
                  </Row>
                </Form>
              </Col>
            </Row>
            <ModalCreate
              modal_standard={this.state.modal_create_product}
              tog_standard={this.togStandard}
              createProduct={() => this.createProduct()}
              prod_imgs={this.state.prod_imgs}
              handleAcceptedFiles={this.handleAcceptedFiles}
              deleteFiles={this.deleteFiles}
              onChangeInput={this.createOnChangeInput}
              categories={this.state.categories}
              sizes={this.state.sizes}
              colors={this.state.colors}
              providers={this.state.providers}
              new_product={this.state.new_product}
            />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ProductsDatatable
                      products={this.state.products}
                      openModal={this.openModal}
                      print={this.print}
                      imAdmin={this.state.imAdmin}
                    />
                    <Pages
                      page={this.state.page}
                      page_size={this.state.page_size}
                      total_rows={this.state.total_rows}
                      onClickPage={value => this.setPage(value)}
                    />
                    <ModalUpdateProduct
                      modal_standard={this.state.modal_standard}
                      tog_standard={this.togStandard}
                      product={this.state.current_product}
                      onChangeInput={this.updateCurrentProductInput}
                      updateCurrentProduct={this.updateCurrentProduct}
                      categories={this.state.categories}
                      sizes={this.state.sizes}
                      colors={this.state.colors}
                      providers={this.state.providers}
                      handleAcceptedFiles={this.handleUpdateFiles}
                      deleteFiles={this.deleteFiles}
                      prod_imgs={this.state.prod_imgs}
                    />
                    <ModalDeleteProduct
                      modal_standard={this.state.modal_delete}
                      tog_standard={this.togStandard}
                      product={this.state.current_product}
                      deleteCurrentProduct={this.deleteCurrentProduct}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Product;
