import React from 'react'
import {Card, CardBody, InputGroup, InputGroupAddon, Button, Input} from "reactstrap";
import logo from "../../assets/images/logo-full.jpg";
import './sell.scss';

const text_amount = (amount) => {
  return `$ ${(amount * 1).toFixed(2)}`
}
const product_url = (image_url) => {
  return image_url? image_url : logo;
}
const render_rows = (products, changeQuantity ) => {
  if(!products) return "";

  const renderRow = (product) => (
    <tr key={"product_" + product.id}>
      <td>
        <div
          className="img-background"
          style={{backgroundImage: `url(${product_url(product.image_url)})`}}
        />
      </td>
      <td style={{ whiteSpace: "normal" }}>
        <h5 className="font-size-14 text-truncate" style={{whiteSpace: 'normal'}}>{product.name}</h5>
        <p className="mb-0" style={{whiteSpace: 'normal'}}>sku: <span className="font-weight-medium">{product.sku}</span></p>
        <p className="mb-0" style={{whiteSpace: 'normal'}}>codigo: <span className="font-weight-medium">{product.code}</span></p>
        <p className="mb-0" style={{whiteSpace: 'normal'}}>proveedor: <span className="font-weight-medium">{product.provider_name}</span></p>
      </td>
      <td style={{ whiteSpace: "normal" }}>
        <p className="mb-0" style={{whiteSpace: 'normal'}}>color: <span className="font-weight-medium">{product.color}</span></p>
        <p className="mb-0" style={{whiteSpace: 'normal'}}>talle: <span className="font-weight-medium">{product.size}</span></p>
        <p className="mb-0" style={{whiteSpace: 'normal'}}>categoria: <span className="font-weight-medium">{product.category_name}</span></p>
        <p className="mb-0" style={{whiteSpace: 'normal'}}>marca: <span className="font-weight-medium">{product.brand_name}</span></p>
      </td>
      <td style={{ whiteSpace: "normal", textAlign: 'right' }}>
        <h5 className="font-size-14 text-truncate">{text_amount(product.price)}</h5>
        <div className="colMax600Block" style={{ width: "120px" }}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button color="primary"
                      style={{ backgroundColor: 'rgb(174,174,174)', borderColor: 'rgb(177,177,177)' }}
                      onClick={() => changeQuantity(product, product.qty + 1)}>+</Button>
            </InputGroupAddon>
            <Input type="text" value={product.qty} name="demo_vertical"
                   onChange={(e) => {changeQuantity(product, parseInt(e.target.value))} }/>
            <InputGroupAddon addonType="append">
              <Button color="primary"
                      style={{ backgroundColor: 'rgb(174,174,174)', borderColor: 'rgb(177,177,177)' }}
                      onClick={() => changeQuantity(product, product.qty > 0 ? product.qty - 1 : 0)}>-</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <h5 className="font-size-14 text-truncate colMax600Block">{text_amount(product.price * product.qty)}</h5>
      </td>
      <td className="ddisable" style={{ whiteSpace: "normal" }}>
          <InputGroup>
          { product.qty == 0 ?
              <InputGroupAddon addonType="prepend">
                <Button color="primary"
                        style={{ backgroundColor: 'rgb(174,174,174)', borderColor: 'rgb(177,177,177)' }}
                        onClick={() => changeQuantity(product, product.qty + 1)}>+</Button>
              </InputGroupAddon>
              :
              <InputGroupAddon addonType="append">
                <Button color="primary"
                        style={{ backgroundColor: 'rgb(174,174,174)', borderColor: 'rgb(177,177,177)' }}
                        onClick={() => changeQuantity(product, product.qty > 0 ? product.qty - 1 : 0)}>-</Button>
              </InputGroupAddon>
          }
          </InputGroup>
      </td>
    </tr>
  )
  return products.map(renderRow)
}
const SellDatatable = ({ products, changeQuantity, cart=false, total, discount }) => (
  <Card>
    <CardBody>
      <div className="table-responsive" style={{overflowY: 'hidden'}}>
        <table className="table table-centered table-nowrap mb-0">
          <thead className="thead-light">
          <tr>
            <th/>
            <th>Nombre</th>
            <th>Otros</th>
            <th>Precio</th>
            <th className="ddisable"/>
          </tr>
          </thead>
          <tbody>
          { render_rows(products, changeQuantity) }
          { cart ?
            <tr className="colMax600Block">
              <td/>
              <td/>
              <td>SubTotal</td>
              <td style={{textAlign: 'right'}}><h5 className="font-size-14 text-truncate">{text_amount(total)}</h5></td>
            </tr> : ""
          }
          { cart ?
            <tr className="colMax600Block">
              <td/>
              <td/>
              <td>Descuento</td>
              <td style={{textAlign: 'right'}}><h5 className="font-size-14 text-truncate">{text_amount(discount * -1)}</h5></td>
            </tr> : ""
          }
          { cart ?
          <tr className="colMax600Block">
            <td/>
            <td/>
            <td>Total</td>
            <td style={{textAlign: 'right'}}><h5 className="font-size-14 text-truncate">{text_amount(total - discount)}</h5></td>
          </tr> : ""
          }
          </tbody>
        </table>
      </div>
    </CardBody>
  </Card>
)

export default SellDatatable
