import React from 'react'
import {Card, CardBody, Button} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom"

const text_amount = (amount) => {
  return `$ ${(amount * 1).toFixed(2)}`
}

const render_rows = (invoice_products) => {
  if(!invoice_products) return "";

  const renderRow = (invoice_product) => (
    <tr key={"product_" + invoice_product.id}>
      <td style={{width: '100px', textAlign: 'left'}}>{invoice_product.product.code}</td>
      <td style={{ whiteSpace: "normal" }}>{invoice_product.name}</td>
      <td style={{width: '100px', textAlign: 'right'}}/>
      <td style={{width: '100px', textAlign: 'right'}}>{text_amount(invoice_product.price)}</td>
    </tr>
  )
  return invoice_products.map(renderRow)
}
const InvoiceProductsDatatable = ({ invoice_products, invoice, togStandard }) => (
  <Card>
    <CardBody>
      <div className="table-responsive" style={{overflowY: 'hidden'}}>
        <table className="table table-centered table-nowrap mb-0">
          <thead className="thead-light">
          <tr>
            <th style={{width: '100px', textAlign: 'left'}}>#</th>
            <th style={{ whiteSpace: "normal" }}>Productos</th>
            <th style={{width: '100px', textAlign: 'right'}}/>
            <th style={{width: '100px', textAlign: 'right'}}>Monto</th>
          </tr>
          </thead>
          <tbody>
          { render_rows(invoice_products) }
          <tr >
            <th style={{width: '100px', textAlign: 'right'}}/>
            <td style={{ whiteSpace: "normal" }}/>
            <td style={{width: '100px', textAlign: 'right', paddingTop: '12px', fontWeight: '500'}}>Comisión e-commerce</td>
            <td style={{width: '100px', textAlign: 'right', fontSize: '14px', fontWeight: '500'}}>{text_amount(invoice.woo_fee)}</td>
          </tr>
          <tr >
            <th style={{width: '100px', textAlign: 'right'}}/>
            <td style={{ whiteSpace: "normal" }}/>
            <td style={{width: '100px', textAlign: 'right', paddingTop: '12px', fontWeight: '500'}}>Descuento e-commerce</td>
            <td style={{width: '100px', textAlign: 'right', fontSize: '14px', fontWeight: '500'}}>{text_amount(invoice.discount_woo * -1)}</td>
          </tr>
          <tr >
            <th style={{width: '100px', textAlign: 'right'}}/>
            <td style={{ whiteSpace: "normal" }}/>
            <td style={{width: '100px', textAlign: 'right', paddingTop: '12px', fontWeight: '500'}}>SubTotal</td>
            <td style={{width: '100px', textAlign: 'right', fontSize: '14px', fontWeight: '500'}}>{text_amount(invoice.amount)}</td>
          </tr>
          <tr >
            <th style={{width: '100px', textAlign: 'right'}}/>
            <td style={{ whiteSpace: "normal" }}/>
            <td style={{width: '100px', textAlign: 'right', paddingTop: '12px', fontWeight: '500'}}><Link href="#" className="bx bx-edit font-size-20 mr-2 product-new" style={{ color: 'rgb(179, 142, 132)', float: 'left' }} onClick={() => togStandard('discount')}/>Descuento</td>
            <td style={{width: '100px', textAlign: 'right', fontSize: '14px', fontWeight: '500'}}>{text_amount(invoice.discount)}</td>
          </tr>
          <tr >
            <th style={{width: '100px', textAlign: 'right'}}/>
            <td style={{ whiteSpace: "normal" }}/>
            <td style={{width: '100px', textAlign: 'right', paddingTop: '12px', fontWeight: '500'}}>Total</td>
            <td style={{width: '100px', textAlign: 'right', fontSize: '14px', fontWeight: '500'}}>{text_amount(invoice.amount - invoice.discount)}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </CardBody>
  </Card>
)

export default InvoiceProductsDatatable
