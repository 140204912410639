import React from 'react'
import {CardBody, Card, Table, CardTitle, Tooltip, Input} from "reactstrap";

const text_amount = (amount) => {
    return `$ ${(amount * 1).toFixed(2)}`
}

const items = (products) => {
    return products.map((product) =>
        <tr key={"item_product" + product.id}>
            <td>{product.name}</td>
            <td style={{textAlign: 'right', minWidth: '100px'}}>{text_amount(product.price)}</td>
        </tr>
    );
}

const render_body = (total, discount, products) => {
    return <tbody>
    { items(products) }
    <tr>
      <td>SubTotal</td>
      <td style={{textAlign: 'right'}}>{text_amount(total)}</td>
    </tr>
    <tr>
        <td>Descuento</td>
        <td style={{textAlign: 'right'}}>{text_amount(discount * -1)}</td>
    </tr>
    <tr>
        <td>Total</td>
        <td style={{textAlign: 'right'}}>{text_amount(total - discount)}</td>
    </tr>
    </tbody>
}

const Summary = ({total, discount, products}) => (

          <div className="table-responsive">
              <table className="table table-centered  mb-0">
                  <thead className="thead-light">
                  <tr>
                      <th>Nombre</th>
                      <th style={{textAlign: 'right', minWidth: '100px'}}>Precio</th>
                  </tr>
                  </thead>
                  {
                      render_body(total, discount, products)
                  }
              </table>
          </div>
)

export default Summary
