const apiConfig = {
    //baseUrl: "http://3.133.141.123:81/",
    //apiUrl: "http://3.133.141.123:81/api/v1/"

    baseUrl: "https://api.peppers.uy/",
    apiUrl: "https://api.peppers.uy/api/v1/",

    //baseUrl: "http://3.139.66.165:81/",
    //apiUrl: "http://3.139.66.165:81/api/v1/"
};

const apiPaths = {
    login: apiConfig.baseUrl + "auth/sign_in",
    registration: apiConfig.baseUrl + "auth",

    categories: apiConfig.apiUrl + "categories",
    brands: apiConfig.apiUrl + "brands",
    products: apiConfig.apiUrl + "products",
    sold: apiConfig.apiUrl + "sold",
    users: apiConfig.apiUrl + "users",
    invoices: apiConfig.apiUrl + "invoices",
    providers: apiConfig.apiUrl + "providers",
};
export{
    apiConfig, apiPaths
}
