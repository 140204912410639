import React, { Component } from "react"
import { Container, Col, Row, Card, CardBody, Form, FormGroup, Button, CardTitle, Input, Label } from "reactstrap"
import {Link} from "react-router-dom";
import {apiPaths} from "../../config/constants";
import mainFunctions from "../../components/Functions";
import Request from "superagent";
import SoldDatatable from "./datatable"
import toastr from 'toastr'
import Pages from "../../components/Pages"
import Select from "react-select"
import './sold.scss';
import SweetAlert from "react-bootstrap-sweetalert"

class Sold extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      page_size: 50,
      total_rows: 0,
      providers: mainFunctions.getProviders(),
      resume: {amount_without_pay: 0},
      imAdmin: mainFunctions.imAdmin(),
      options: [{ label: 'Todas', value: 'all' }, { label: 'No abonados', value: 'without_pay' }, { label: 'Pagos', value: 'paid' }],
      option: 'all',
      next_payment_note: '',
      payment_methods: [{ label: 'Giro bancario', value: 'bank_draft' }, { label: 'Efectivo', value: 'cash' }],
      payment_method: null,
      user_note: ''
    }

    this.next_payment_note = React.createRef();
  }
  componentDidMount() {
    this.nextPaymentNote();
    this.products();
  }
  nextPaymentNote = () => {
    const {payment_methods} = this.state;

    Request
      .get(`${apiPaths.users}/next_payment`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send()
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          const { next_payment_note, payment_method, user_note } = response.body;

          this.setState({
            next_payment_note: next_payment_note,
            payment_method: payment_methods.find(x => x.value === payment_method),
            user_note: user_note || ""
          });
        } else {

        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  products = () => {
    setTimeout(function() {
      const { page, page_size, provider_id, option, q } = this.state;
      let query = { page: page, pageSize: page_size, option: option, q };
      if(provider_id) query.provider_id = provider_id;

      Request
        .get(apiPaths.sold + mainFunctions.formattedGet(query, false))
        .set('Accept', 'application/json')
        .set(mainFunctions.headers())
        .then((response) => {
          if(response.status >= 200 && response.status <= 299) {
            let { products, total_rows, resume, payment_method_name, user_note } = response.body.data;

            this.setState({
              products: products,
              total_rows: total_rows,
              resume: resume,
              payment_method_name: payment_method_name
            });

            if(provider_id) this.setState({ user_note: user_note || "" });
          }
        }).catch((error) => {
        console.log('error sold')
        window.location.assign("/login");
        this.setState({
          errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
        })
      });
    }.bind(this), 20);
  };
  setPage = (value) => {
    this.setState({ page: value });
    this.products();
  };
  handleOptionsChange = (item, attr) => {
    switch(attr) {
      case 'provider':
        this.setState({ provider_id: item.value })
        break;
      case 'option':
        this.setState({ option: item.value })
        break;
      default:

    }
    this.setState({ page: 1 })
    this.products();
  }
  payOff = () => {
    const {provider_id} = this.state;

    Request
      .post(`${apiPaths.sold}/pay_off`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send({provider_id: provider_id})
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          this.products();
        } else {
          toastr["warning"]("Error inesperado", "Venta");
        }

      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  updateNextPaymentNote = () => {
    const next_payment_note = this.next_payment_note.current.value;

    Request
      .post(`${apiPaths.users}/set_next_payment`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send({next_payment_note: next_payment_note})
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {

        } else {

        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  updatePaymentMethod = (item) => {
    Request
      .post(`${apiPaths.users}/payment_method`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send({payment_method: item.value})
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          window.location.assign("/vendidos");
        } else {

        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  createOnChangeInput = (value, attr) => {
    let { user_note } = this.state;
    switch(attr) {
      case 'user_note':
        user_note = value;
        this.changeUserNote(value);
        break;
      default:

    }

    this.setState({user_note});
  };

  changeUserNote = (user_note) => {
    setTimeout(function() {
      const { user_note: now_user_note} = this.state;
      if(user_note === now_user_note){
        Request
          .post(`${apiPaths.users}/set_user_note`)
          .set('Accept', 'application/json')
          .set(mainFunctions.headers())
          .send({user_note: user_note})
          .then((response) => {
            if(response.status >= 200 && response.status <= 299) {

            }
          }).catch((error) => {
          this.setState({
            isSending: false,
            errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
          })
        });
      }
    }.bind(this), 2000);
  }
  find = (event) => {
    event.preventDefault();
    this.setState({ q: event.target.value });
    this.products();
  };
  rollback = (product) => {
    this.setState({ openRollback: true, rollback_product: product });
  }
  revertSold = () => {
    this.setState({ openRollback: false});
    const { rollback_product} = this.state;
    let { id } = rollback_product;
    Request
      .post(`${apiPaths.products}/${id}/revert_sold`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) this.products();
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  render() {
    const { provider_id, user_note, rollback_product } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row className="colMin600">
              <Col xs="12">
                <FormGroup style={{marginTop: '15px'}}>
                  <div className="search-box mr-4" style={{marginRight: '10px'}}>
                    <div className="position-relative" style={{float: 'right', width: '200px'}}>
                      <Input
                        type="text"
                        className="form-control input-border"
                        placeholder="Buscar..."
                        value={this.state.q}
                        onChange={this.find}
                      />
                      <i className="bx bx-search-alt search-icon"/>
                    </div>
                  </div>
                </FormGroup>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">Productos Vendidos</h4>
                  <div className="page-title-right d-flex">
                    <Select
                      classNamePrefix="select1-selection"
                      className="select_options"
                      placeholder="Opciones..."
                      options={this.state.options}
                      onChange={(item) => this.handleOptionsChange(item, 'option')}
                    />
                    {
                      this.state.imAdmin ?
                        <Select
                          classNamePrefix="select1-selection"
                          className="select_options"
                          placeholder="Proveedor..."
                          options={this.state.providers}
                          onChange={(item) => this.handleOptionsChange(item, 'provider')}
                        />
                        :
                        ''
                    }
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3 colMax600">
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">Productos Vendidos</h4>
                </div>
              </Col>
              <Col xs="12">
                <Row>
                  <FormGroup style={{marginTop: '15px'}}>
                    <div className="search-box mr-4" style={{marginRight: '10px'}}>
                      <div className="position-relative" style={{float: 'right', width: '200px'}}>
                        <Input
                          type="text"
                          className="form-control input-border"
                          placeholder="Buscar..."
                          value={this.state.q}
                          onChange={this.find}
                        />
                        <i className="bx bx-search-alt search-icon"/>
                      </div>
                    </div>
                  </FormGroup>
                </Row>
                <Form>
                  <Row>
                    <Col xs="12">
                      <FormGroup style={{marginTop: '15px'}}>
                        <Select
                          classNamePrefix="select1-selection"
                          className="select_options select_options_xs select_options_z10"
                          placeholder="Opciones..."
                          options={this.state.options}
                          onChange={(item) => this.handleOptionsChange(item, 'option')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {
                      this.state.imAdmin ?
                        <Col xs="12">
                          <FormGroup style={{marginTop: '15px'}}>
                            <Select
                              classNamePrefix="select1-selection"
                              className="select_options select_options_xs select_options_z09"
                              placeholder="Proveedor..."
                              options={this.state.providers}
                              onChange={(item) => this.handleOptionsChange(item, 'provider')}
                            />
                          </FormGroup>
                        </Col>
                        :
                        ''
                    }
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="9" xs="12">
                        <SoldDatatable
                          products={this.state.products}
                          rollback={(product) => this.rollback(product)}
                        />
                        <Pages
                          page={this.state.page}
                          page_size={this.state.page_size}
                          total_rows={this.state.total_rows}
                          onClickPage={value => this.setPage(value)}
                        />
                      </Col>
                      <Col sm="3" xs="12">
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-3" style={{marginTop: '8px'}}>Resumen</CardTitle>
                            {
                              this.state.imAdmin ?
                                <div className="mt-3">
                                  <Input
                                    type="textarea"
                                    id="textarea"
                                    innerRef={this.next_payment_note}
                                    defaultValue={this.state.next_payment_note}
                                    rows="5"
                                    placeholder="Fecha del proximo pago"
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    style={{  width: 100, backgroundColor: 'rgb(141,141,141)', borderColor: 'rgb(160,160,160)', margin: '10px' }}
                                    onClick={() => this.updateNextPaymentNote()}
                                  >
                                    Actualizar
                                  </button>
                                </div>
                                :
                                <Input
                                  type="textarea"
                                  id="textarea"
                                  disabled={true}
                                  defaultValue={this.state.next_payment_note}
                                  rows="5"
                                  placeholder="Fecha del proximo pago"
                                />
                            }
                            <div className="table-responsive" style={{minHeight: '500px'}}>
                              <table className="table table-centered table-nowrap mb-0">
                                <tbody>
                                <tr style={{height: '51px', borderTop: '1px solid rgb(239, 242, 247)'}}>
                                  <td>Productos pagos</td>
                                  <td>{this.state.resume.total_paid}</td>
                                </tr>
                                <tr style={{height: '51px', borderTop: '1px solid rgb(239, 242, 247)'}}>
                                  <td>Productos no abonados</td>
                                  <td>{this.state.resume.total_without_pay}</td>
                                </tr>
                                <tr style={{height: '51px'}}>
                                  <td>Total pago</td>
                                  <td>{`$ ${(this.state.resume.amount_paid * 1).toFixed(2)}`}</td>
                                </tr>
                                <tr style={{height: '51px'}}>
                                  <td>Total no abonado</td>
                                  <td>{`$ ${(this.state.resume.amount_without_pay * 1).toFixed(2)}`}</td>
                                </tr>
                                {
                                  this.state.payment_method_name ?
                                    <tr style={{height: '51px'}}>
                                      <td>Método de cobro</td>
                                      <td>{this.state.payment_method_name}</td>
                                    </tr>
                                    :
                                    null
                                }
                                </tbody>
                              </table>
                              {
                                this.state.imAdmin ?
                                  null
                                  :
                                  <div>
                                    <FormGroup>
                                      <Label htmlFor="bundle_qty" style={{fontWeight: 'bold'}}>Ingrese el número de cuenta bancaria, banco, y nombre</Label>
                                      <Input
                                        autoComplete="off"
                                        type="textarea"
                                        className="form-control"
                                        placeholder="Ingrese el número de cuenta bancaria, banco, y nombre"
                                        onChange={ e => {this.createOnChangeInput(e.target.value, 'user_note')} }
                                        value={user_note}
                                      />
                                    </FormGroup>
                                  </div>
                              }
                              {
                                this.state.imAdmin && provider_id ?
                                  <div>
                                    <FormGroup>
                                      <Label htmlFor="bundle_qty">Cuenta bancaria del usuario</Label>
                                      <Input
                                        autoComplete="off"
                                        type="textarea"
                                        className="form-control"
                                        value={user_note}
                                        disabled={true}
                                      />
                                    </FormGroup>
                                  </div>
                                  :
                                  null
                              }
                              {
                                this.state.imAdmin?
                                  <Button
                                    to="#"
                                    onClick={() => this.setState({ pay_off: true })}
                                    disabled={!this.state.provider_id || (this.state.resume.amount_without_pay * 1) === 0}
                                    className="btn btn-primary waves-effect waves-light me-1"
                                    style={{  width: 100, backgroundColor: 'rgb(141,141,141)', borderColor: 'rgb(160,160,160)', margin: '10px' }}>
                                    >
                                    Liquidar
                                  </Button>
                                  :
                                  <div>
                                    <span>Método de cobro</span>
                                    <Select
                                      classNamePrefix="select1-selection"
                                      className="select_options"
                                      placeholder="Seleccionar..."
                                      options={this.state.payment_methods}
                                      value={this.state.payment_method}
                                      onChange={(item) => this.updatePaymentMethod(item)}
                                    />
                                  </div>
                              }
                              {this.state.pay_off ? (
                                  <SweetAlert
                                    title="Confirmar liquidación"
                                    warning
                                    showCancel
                                    confirmBtnText="Aceptar"
                                    cancelBtnText="Cancelar"
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.setState({
                                        pay_off: false,
                                        success_dlg: this.payOff(),
                                      })
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        pay_off: false,
                                      })
                                    }
                                  >
                                    ¡No podrás revertir esto! <br/>
                                    Total: {`$ ${(this.state.resume.amount_without_pay * 1).toFixed(2)}`}
                                  </SweetAlert>
                                )
                                :
                                null
                              }
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.openRollback ? (
                <SweetAlert
                  title="Revertir venta"
                  warning
                  showCancel
                  confirmBtnText="Revertir"
                  cancelBtnText="No"
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => this.revertSold()}
                  onCancel={() => this.setState({openRollback: false})}
                >
                  {rollback_product.name}
                </SweetAlert>
              )
              :
              null
            }
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Sold;
