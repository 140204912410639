import React from 'react'
import {Card, CardBody, Tooltip} from "reactstrap";
import {Link} from "react-router-dom";

const render_rows = (brands, open_brand_modal, delete_modal ) => {
    if(!brands) return "";

    const renderRow = (brand) => (
        <tr key={"product_" + brand.id}>
            <td style={{ whiteSpace: "normal" }}>
                {brand.name}
            </td>
            <td style={{ whiteSpace: "normal" }}>
                {brand.total_products}
            </td>
            <td style={{ whiteSpace: "normal" }}>
                <Link to="#" className="action-icon text-danger icon-margin" onClick={() => open_brand_modal(brand)}> <i className="bx bx-edit font-size-22"/></Link>
                <Link to="#" className="action-icon text-danger icon-margin" onClick={() => delete_modal(brand)}> <i className="mdi mdi-trash-can font-size-22"/></Link>
            </td>
        </tr>
    )
    return brands.map(renderRow)
}
const BrandsDatatable = ({ brands, open_brand_modal, delete_modal }) => (
    <Card>
        <CardBody>
            <div className="table-responsive" style={{overflowY: 'hidden'}}>
                <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                    <tr>
                        <th>Marca</th>
                        <th>Productos</th>
                        <th style={{ width: 100 }}/>
                    </tr>
                    </thead>
                    <tbody>
                    { render_rows(brands, open_brand_modal, delete_modal) }
                    </tbody>
                </table>
            </div>
        </CardBody>
    </Card>
)

export default BrandsDatatable
