import React from 'react';
import {CardBody, Form, FormGroup, Input, Label, Modal, Row, Col} from "reactstrap";
import DropImage from "../../components/DropImage";
import Select from "react-select";
import { Link } from "react-router-dom"
import Switch from "react-switch"

const render_rows = (tog_standard, modal_standard, createProduct, prod_imgs, handleAcceptedFiles, onChangeInput, categories, sizes, colors, providers, new_product, deleteFiles) => {
  const { provider_price, in_woocommerce } = new_product;

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
      </div>
    )
  }
  const OnSymbol = props => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
      </div>
    )
  }
  return (
    <Modal
      size="xl"
      isOpen={modal_standard}
      toggle={() => tog_standard('create')}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Nuevo producto
        </h5>
        <button type="button"
                onClick={() => tog_standard('create')}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <Row>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="name">Nombre</Label>
                <Input autoComplete="off" type="text" className="form-control" onChange={ e => {onChangeInput(e.target.value, 'name')} } />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="code">Codigo</Label>
                <Input autoComplete="off" type="text" className="form-control" onChange={ e => {onChangeInput(e.target.value, 'code')} } />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="sku">SKU</Label>
                <Input autoComplete="off" type="text" className="form-control" onChange={ e => {onChangeInput(e.target.value, 'sku')} } />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="brand">Proveedor</Label>
                <Select
                  classNamePrefix="select1-selection"
                  onChange={ provider => {onChangeInput( provider, 'provider')} }
                  options={ providers.slice(1) }
                  placeholder="Proveedor..."
                />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="price">Precio</Label>
                <Input autoComplete="off" type="number" className="form-control" onChange={ e => {onChangeInput(Number(e.target.value), 'price')} } />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="provider_price">Precio del Proveedor</Label>
                <Input autoComplete="off" type="number" className="form-control" value={provider_price} onChange={ e => {onChangeInput(Number(e.target.value), 'provider_price')} } />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="category">Categoria</Label>
                <Select
                  classNamePrefix="select1-selection"
                  onChange={ category => {onChangeInput( category, 'category')} }
                  options={ categories }
                  defaultValue={categories[0]}
                />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="size">Tamaño</Label>
                <Select
                  classNamePrefix="select1-selection"
                  onChange={ size => {onChangeInput( size, 'size')} }
                  options={ sizes }
                  defaultValue={sizes[0]}
                />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="color">Color</Label>
                <Select
                  classNamePrefix="select1-selection"
                  onChange={ color => {onChangeInput( color, 'color')} }
                  options={ colors }
                  defaultValue={colors[0]}
                />
              </FormGroup>
            </Col>
            <Col sm="12 mt-3">
              <div className="mb-3 row">
                <label className="col-md-2 col-form-label">
                  WooCommerce
                </label>
                <div className="col-md-10">
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    className="me-1 mb-sm-8 mt-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={ e => {onChangeInput(!in_woocommerce, 'in_woocommerce')} }
                    checked={in_woocommerce}
                  />
                </div>
              </div>
            </Col>
            <Col sm="12">
              <FormGroup>
                <Label htmlFor="bundle_qty">Descripción</Label>
                <textarea className="form-control" rows="2" style={{marginBottom: '10px'}} onChange={ e => {onChangeInput(e.target.value, 'description')} } />
              </FormGroup>
            </Col>
            <Col sm="2">
              <Col sm="12" className="text-center">
                <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(0) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
              </Col>
              <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 0) } drop_file_type={'Imágen principal'} images={ prod_imgs[0] } />
            </Col>
            <Col sum="2"/>
            <Col sm="2">
              <Col sm="12" className="text-center">
                <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(1) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
              </Col>
              <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 1) } drop_file_type={'Imágen 1'} images={ prod_imgs[1] } />
            </Col>
            <Col sm="2">
              <Col sm="12" className="text-center">
                <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(2) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
              </Col>
              <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 2) } drop_file_type={'Imágen 2'} images={ prod_imgs[2] } />
            </Col>
            <Col sm="2">
              <Col sm="12" className="text-center">
                <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(3) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
              </Col>
              <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 3) } drop_file_type={'Imágen 3'} images={ prod_imgs[3] } />
            </Col>
            <Col sm="2">
              <Col sm="12" className="text-center">
                <Link to="#" className="action-icon text-danger icon-margin" onClick={ () => deleteFiles(4) }> <i className="mdi mdi-trash-can font-size-22"/></Link>
              </Col>
              <DropImage on_drop_files={ (value) => handleAcceptedFiles(value, 4) } drop_file_type={'Imágen 4'} images={ prod_imgs[4] } />
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => tog_standard('create')}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          No
        </button>
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          style={{ backgroundColor: 'rgb(174,174,174)', borderColor: 'rgb(177,177,177)' }}
          onClick={() => createProduct()}
        >
          Crear
        </button>
      </div>
    </Modal>
  );
}

const ModalCreate = ({tog_standard, modal_standard, createProduct, prod_imgs, handleAcceptedFiles, onChangeInput, categories, sizes, colors, providers, new_product, deleteFiles}) => (
  render_rows(tog_standard, modal_standard, createProduct, prod_imgs, handleAcceptedFiles, onChangeInput, categories, sizes, colors, providers, new_product, deleteFiles)
)

export default ModalCreate
