import React, { Component } from "react"
import { Container, Col, Row, Card, CardBody, Input, TabContent, TabPane, NavLink, NavItem, Nav, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Form, FormGroup, Label } from "reactstrap"
import {Link} from "react-router-dom";
import {apiPaths} from "../../config/constants";
import mainFunctions from "../../components/Functions";
import Request from "superagent";
import ProductsDatatable from "./datatable"
import toastr from 'toastr'
import Pages from "../../components/Pages"
import Select from "react-select"
import './sell.scss';
import classnames from "classnames";
import Summary from "./summary"
import SweetAlert from "react-bootstrap-sweetalert"
import ModalDiscount from "./modalDiscount"

class Sell extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      page_size: 50,
      total_rows: 0,
      q: '',
      brands: mainFunctions.getBrands(),
      categories: mainFunctions.getCCategories(),
      providers: mainFunctions.getProviders(),
      payment_methods: mainFunctions.getPaymentMethods(),
      navActiveTab: "1",
      products: [],
      selected_products: [],
      total: 0,
      discount: 0,
      client_name: '',
      note: ''
    }
  }
  componentDidMount() {
    this.products();
  }

  products = () => {
    setTimeout(function() {
      const { page, page_size, q, category_id, brand_id, provider_id } = this.state;
      let query = { page: page, pageSize: page_size, q: q }
      if(category_id) query.category_id = category_id;
      if(brand_id) query.brand_id = brand_id;
      if(provider_id) query.provider_id = provider_id;

      Request
        .get(apiPaths.products + mainFunctions.formattedGet(query, false))
        .set('Accept', 'application/json')
        .set(mainFunctions.headers())
        .then((response) => {
          if (response.body && response.body.data) {
            let { products, total_rows} = response.body.data;
            products.forEach(product => {
              product.available = 1
              product.qty = this.setQty(product);
            });

            this.setState({
              products: products,
              total_rows: total_rows,
            })
          }
        }).catch((error) => {
        this.setState({
          errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
        })
      });
    }.bind(this), 20);
  };
  setQty = (product) => {
    const { id } = product;
    let {selected_products} = this.state;
    let selectedItem = selected_products.find(p => p.id === id );
    return selectedItem ? selectedItem.qty : 0;
  }
  setPage = (value) => {
    this.setState({ page: value });
    this.products();
  };
  handleOptionsChange = (item, attr) => {
    switch(attr) {
      case 'category':
        this.setState({ category_id: item.value })
        break;
      case 'brand':
        this.setState({ brand_id: item.value })
        break;
      case 'provider':
        this.setState({ provider_id: item.value })
        break;
      default:

    }

    this.setState({ page: 1 })
    this.products();
  }
  find = (event) => {
    event.preventDefault();
    this.setState({ q: event.target.value });
    if(event.target.value.length < 7) return;
    this.products();
  };
  toggle(tab) {
    if (this.state.navActiveTab !== tab) {
      this.setState({
        navActiveTab: tab
      });
    }
  };
  paymentMethods = () => {
    return this.state.payment_methods.map((payment_method) =>
      <DropdownItem key={"payment_method" + payment_method.value} onClick={() => this.setPaymentMethod(payment_method)} >{payment_method.label}</DropdownItem>
    );
  }
  setPaymentMethod = (payment_method) => {
    this.setState({
      confirm_alert: true,
      payment_method: payment_method
    })
  }
  completeTransaction = () => {
    const { payment_method, selected_products, discount, client_name, note } = this.state;

    let post = {
      products: selected_products.map((product) => { return product.id }),
      payment_method_id: payment_method.value,
      discount: discount,
      client_name: client_name,
      note: note
    }
    toastr.options = mainFunctions.toastrOptions();

    Request
      .post(apiPaths.invoices)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send(post)
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          const { invoice } = response.body.data;
          const { id } = invoice;
          window.location.assign(`/orden/${id}?print=true`);
        } else {
          toastr["warning"]("Error inesperado", "Venta");
        }

      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  togStandard = (attr) => {
    switch(attr) {
      case 'discount':
        this.setState({ modal_discount: !this.state.modal_discount });
        break;
      default:

    }
  };
  createOnChangeInput = (value, attr) => {
    switch(attr) {
      case 'discount':
        this.setState({discount: value});
        break;
      case 'discount_close':
        this.setState({discount: 0, modal_discount: false});
        break;
      case 'client_name':
        this.setState({client_name: value});
        break;
      case 'note':
        this.setState({note: value});
        break;
      default:

    }
  };
  changeQuantity = (product, quantity) => {
    let {id, available} = product;
    let {products, selected_products} = this.state;
    if(!quantity || quantity < 0) quantity = 0;
    if(quantity > available) quantity = available;

    this.setState({ products: products.map(ip => (ip.id === id ? { ...ip, qty: quantity } : ip)) });

    if(quantity === 0){
      const filteredItems = selected_products.filter(p => p.id !== id )
      this.setState({ selected_products: filteredItems });
    }else{
      let selectedItem = selected_products.find(p => p.id === id );
      product.qty = quantity;

      if(selectedItem) this.setState({ selected_products: selected_products.map(ip => (ip.id === id ? { ...ip, qty: quantity } : ip)) });
      else this.setState({ selected_products: [...selected_products, ...[product]] });
    }

    this.setState({ q: '' });

    setTimeout(function() {
      let total = 0;
      this.state.selected_products.forEach(product => { total += product.price * product.qty; });
      this.setState({ total: total });
    }.bind(this), 20);
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.confirm_alert ? (
              <SweetAlert
                title="Confirmar venta"
                warning
                showCancel
                confirmBtnText="Aceptar"
                cancelBtnText="Cancelar"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() =>
                  this.setState({
                    confirm_alert: false,
                    success_dlg: this.completeTransaction(),
                  })
                }
                onCancel={() =>
                  this.setState({
                    confirm_alert: false,
                  })
                }
              >
                ¡No podrás revertir esto! <br/>
                Método de pago: {this.state.payment_method.label}
              </SweetAlert>
            ) : null}
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">Venta</h4>
                  <div className="page-title-right d-flex">
                    <Input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="Nombre del comprador"
                      style={{width: '220px'}}
                      onChange={ e => {this.createOnChangeInput(e.target.value, 'client_name')} }
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="8" xs={12}>
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({ active: this.state.navActiveTab === "1" })}
                          onClick={() => { this.toggle("1"); }}
                        >
                          Productos
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({ active: this.state.navActiveTab === "2" })}
                          onClick={() => { this.toggle("2"); }}
                        >
                          Carrito
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.navActiveTab}>
                      <TabPane tabId="1" className="p-3">
                        <div className="page-title-right d-flex colMin600">
                          <div className="search-box mr-4" style={{marginRight: '10px'}}>
                            <div className="position-relative">
                              <Input
                                type="text"
                                className="form-control input-border"
                                placeholder="Buscar..."
                                value={this.state.q}
                                onChange={this.find}
                              />
                              <i className="bx bx-search-alt search-icon"/>
                            </div>
                          </div>
                          <Select
                            classNamePrefix="select1-selection"
                            className="select_options"
                            placeholder="Marcas..."
                            options={this.state.brands}
                            onChange={(item) => this.handleOptionsChange(item, 'brand')}
                          />
                          <Select
                            classNamePrefix="select1-selection"
                            className="select_options"
                            placeholder="Categoria..."
                            options={this.state.categories}
                            onChange={(item) => this.handleOptionsChange(item, 'category')}
                          />
                          <Select
                            classNamePrefix="select1-selection"
                            className="select_options"
                            placeholder="Proveedor..."
                            options={this.state.providers}
                            onChange={(item) => this.handleOptionsChange(item, 'provider')}
                          />
                        </div>
                        <Row className="mb-3 colMax600">
                          <Form>
                            <Row>
                              <Col xs="12">
                                <FormGroup style={{marginTop: '15px'}}>
                                  <div className="search-box mr-4" style={{marginRight: '10px'}}>
                                    <div className="position-relative" style={{float: 'right', width: '200px'}}>
                                      <Input
                                        type="text"
                                        className="form-control input-border"
                                        placeholder="Buscar..."
                                        value={this.state.q}
                                        onChange={this.find}
                                      />
                                      <i className="bx bx-search-alt search-icon"/>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup style={{marginTop: '15px'}}>
                                  <Select
                                    classNamePrefix="select1-selection"
                                    className="select_options select_options_xs select_options_z10"
                                    placeholder="Marcas..."
                                    options={this.state.brands}
                                    onChange={(item) => this.handleOptionsChange(item, 'brand')}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup style={{marginTop: '15px'}}>
                                  <Select
                                    classNamePrefix="select1-selection"
                                    className="select_options select_options_xs select_options_z9"
                                    placeholder="Categoria..."
                                    options={this.state.categories}
                                    onChange={(item) => this.handleOptionsChange(item, 'category')}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup style={{marginTop: '15px'}}>
                                  <Select
                                    classNamePrefix="select1-selection"
                                    className="select_options select_options_xs select_options_z8"
                                    placeholder="Proveedor..."
                                    options={this.state.providers}
                                    onChange={(item) => this.handleOptionsChange(item, 'provider')}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </Row>
                        <ProductsDatatable
                          products={this.state.products}
                          changeQuantity={(id, quantity) => this.changeQuantity(id, quantity)}
                        />
                        <Pages
                          page={this.state.page}
                          page_size={this.state.page_size}
                          total_rows={this.state.total_rows}
                          onClickPage={value => this.setPage(value)}
                        />
                      </TabPane>
                      <TabPane tabId="2" className="p-3">

                        <Row  className="colMax600">
                          <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                              <h4> </h4>
                              <div className="page-title-right d-flex">
                                <Link
                                  to="#"
                                  onClick={() => this.togStandard('discount')}
                                  className="btn btn-primary waves-effect waves-light me-1"
                                >
                                  Descuento
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <ProductsDatatable
                          products={this.state.selected_products}
                          changeQuantity={(id, quantity) => this.changeQuantity(id, quantity)}
                          cart={true}
                          total={this.state.total}
                          discount={this.state.discount}
                        />
                        <div className="colMax600">
                          <div className="div-600-xs">
                            <div className="float-end">
                              <Dropdown
                                style={{marginRight: '20px', marginBottom: '10px'}}
                                isOpen={this.state.btnprimary2}
                                toggle={() => this.setState({ btnprimary2: !this.state.btnprimary2 }) }
                                disabled={this.state.selected_products.length === 0}
                              >
                                <DropdownToggle
                                  tag="button"
                                  className="btn btn-primary"
                                >
                                  Confirmar venta <i className="mdi mdi-chevron-down"/>
                                </DropdownToggle>
                                <DropdownMenu>
                                  { this.paymentMethods() }
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>

              </Col>
              <ModalDiscount
                modal_standard={this.state.modal_discount}
                tog_standard={this.togStandard}
                discount={this.state.discount}
                onChangeInput={this.createOnChangeInput}
              />

              <Col sm="4" className="colMin600">
                <Card style={{width: '100%', marginBottom: '150px'}}>
                  <Card>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="bundle_qty">Nota</Label>
                        <Input
                          autoComplete="off"
                          type="textarea"
                          className="form-control"
                          placeholder="Nota de la venta"
                          onChange={ e => {this.createOnChangeInput(e.target.value, 'note')} }
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <Col xs="12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                          <h4 className="mb-0 font-size-18">Resumen</h4>
                          <div className="page-title-right d-flex">
                            <Link
                              to="#"
                              onClick={() => this.togStandard('discount')}
                              className="btn btn-primary waves-effect waves-light me-1"
                            >
                              Descuento
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Summary
                        total={this.state.total}
                        discount={this.state.discount}
                        products={this.state.selected_products}
                      />
                    </CardBody>
                  </Card>
                  <div>
                    <div className="float-end">
                      <Dropdown
                        style={{marginRight: '20px', marginBottom: '10px'}}
                        isOpen={this.state.btnprimary1}
                        toggle={() => this.setState({ btnprimary1: !this.state.btnprimary1 }) }
                        disabled={this.state.selected_products.length === 0}
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-primary"
                        >
                          Confirmar venta <i className="mdi mdi-chevron-down"/>
                        </DropdownToggle>
                        <DropdownMenu>
                          { this.paymentMethods() }
                        </DropdownMenu>
                      </Dropdown>

                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Sell;
