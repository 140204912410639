import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import mainFunctions from "../../components/Functions";

class Logout extends Component {
  /**
   * Redirect to login
   */
  componentDidMount = () => {
    mainFunctions.logout();
  };

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default withRouter(Logout);
