import React, { Component } from "react"
import { Container, Col, Row, Card, CardBody, Button, CardTitle, FormGroup, Input } from "reactstrap"
import {Link} from "react-router-dom";
import {apiPaths} from "../../config/constants";
import mainFunctions from "../../components/Functions";
import Request from "superagent";
import InvoicesDatatable from "./datatable"
import Pages from "../../components/Pages"
import moment from "moment";
import RangePicker from "../../components/RangePicker"
import Select from "react-select"

class Invoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      page_size: 50,
      total_rows: 0,
      resume: {},
      startDate: moment().subtract(1, 'months').toDate(),
      endDate: moment().toDate(),
      payment_method_id: null,
      payment_methods: [...[{ label: 'Todos', value: null }], ...mainFunctions.getPaymentMethods()],
      users: mainFunctions.getUsers(),
      q: ''
    }

    this.new_category_name_ref = React.createRef();
  }
  componentDidMount() {
    this.invoices();
  }
  invoices = () => {
    setTimeout(function() {
      const { page, page_size, startDate, endDate, payment_method_id , user_id, q } = this.state;

      const start_date = moment(startDate).startOf('day').format();
      const end_date = moment(endDate).endOf('day').format();
      let query = { page, pageSize: page_size, start_date, end_date, payment_method_id, user_id, q }

      Request
        .get(apiPaths.invoices + mainFunctions.formattedGet(query, false))
        .set('Accept', 'application/json')
        .set(mainFunctions.headers())
        .then((response) => {
          if (response.body && response.body.data) {
            let { invoices, total_rows, resume } = response.body.data;
            this.setState({
              invoices: invoices,
              total_rows: total_rows,
              resume: resume
            })
          }
        }).catch((error) => {
        this.setState({
          errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
        })
      });
    }.bind(this), 20);
  };
  setPage = (value) => {
    this.setState({ page: value });
    this.invoices();
  };

  rangePickerChange = (dates) => {
    const { selection } = dates;
    const { startDate, endDate } = selection;

    this.setState({
      startDate: startDate,
      endDate: endDate,
      page: 1
    });
    if(startDate !== endDate) this.TogRangePicker();
    this.invoices();
  };
  TogRangePicker = () => {
    this.setState({ modal_range_picker: !this.state.modal_range_picker });
  };
  handleOptionsChange = (item, attr) => {
    switch(attr) {
      case 'payment_method':
        this.setState({ payment_method_id: item.value })
        break;
      case 'user':
        this.setState({ user_id: item.value })
        break;
      default:

    }

    this.setState({ page: 1 })
    this.invoices();
  }
  find = (event) => {
    event.preventDefault();
    this.setState({ q: event.target.value });
    this.invoices();
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">Ordernes</h4>
                  <div className="page-title-right">

                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="page-title-right d-flex colMin600">
                      <div className="search-box mr-4" style={{marginRight: '10px'}}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control input-border"
                            placeholder="Buscar..."
                            value={this.state.q}
                            onChange={this.find}
                          />
                          <i className="bx bx-search-alt search-icon"/>
                        </div>
                      </div>
                      <Button type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={this.TogRangePicker}
                              data-toggle="modal"
                              style={{marginRight: 10}}
                      >
                        <i className="bx bx-calendar" style={{marginRight: 10}}/>
                        {moment(this.state.startDate).format('ll')} - {moment(this.state.endDate).format('ll')}
                      </Button>
                      <RangePicker
                        startDate={ this.state.startDate }
                        endDate={ this.state.endDate }
                        rangePickerChange={ value => this.rangePickerChange(value) }
                        tog_standard={this.TogRangePicker}
                        modal_standard={this.state.modal_range_picker}
                      />
                      <Select
                        classNamePrefix="select1-selection"
                        className="select_options"
                        placeholder="Método de pago..."
                        options={this.state.payment_methods}
                        onChange={(item) => this.handleOptionsChange(item, 'payment_method')}
                      />
                      <Select
                        classNamePrefix="select1-selection"
                        className="select_options"
                        placeholder="Usuario..."
                        options={this.state.users}
                        onChange={(item) => this.handleOptionsChange(item, 'user')}
                      />
                    </div>
                    <Row className="colMax600">
                      <Row>
                        <FormGroup style={{marginTop: '15px'}}>
                          <div className="search-box mr-4" style={{marginRight: '10px'}}>
                            <div className="position-relative" style={{float: 'right', width: '200px'}}>
                              <Input
                                type="text"
                                className="form-control input-border"
                                placeholder="Buscar..."
                                value={this.state.q}
                                onChange={this.find}
                              />
                              <i className="bx bx-search-alt search-icon"/>
                            </div>
                          </div>
                        </FormGroup>
                      </Row>
                      <Col xs="12" style={{ textAlign: "right", marginTop: '15px'}}>
                        <Button type="button"
                                color="primary"
                                outline
                                className="waves-effect waves-light"
                                onClick={this.TogRangePicker}
                                data-toggle="modal"
                                style={{marginRight: 10}}
                        >
                          <i className="bx bx-calendar" style={{marginRight: 10}}/>
                          {moment(this.state.startDate).format('ll')} - {moment(this.state.endDate).format('ll')}
                        </Button>
                      </Col>
                      <Col xs="12" style={{marginTop: '15px'}}>
                        <Select
                          classNamePrefix="select1-selection"
                          className="select_options select_options_xs"
                          placeholder="Método de pago..."
                          options={this.state.payment_methods}
                          onChange={(item) => this.handleOptionsChange(item, 'payment_method')}
                        />
                      </Col>
                      <Col xs="12" style={{marginTop: '15px'}}>
                        <Select
                          classNamePrefix="select1-selection"
                          className="select_options select_options_xs"
                          placeholder="Usuario..."
                          options={this.state.users}
                          onChange={(item) => this.handleOptionsChange(item, 'user')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="9" xs="12">
                        <InvoicesDatatable
                          invoices={this.state.invoices}
                        />
                        <Pages
                          page={this.state.page}
                          page_size={this.state.page_size}
                          total_rows={this.state.total_rows}
                          onClickPage={value => this.setPage(value)}
                        />
                      </Col>
                      <Col sm="3" xs="12">
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-3" style={{marginTop: '8px'}}>Resumen</CardTitle>
                            <div className="table-responsive">
                              <table className="table table-centered table-nowrap mb-0">
                                <tbody>
                                <tr style={{height: '51px', borderTop: '1px solid rgb(239, 242, 247)'}}>
                                  <td>Ventas</td>
                                  <td>{this.state.resume.total}</td>
                                </tr>
                                <tr style={{height: '51px'}}>
                                  <td>SubTotales</td>
                                  <td>{`$ ${(this.state.resume.amounts * 1).toFixed(2)}`}</td>
                                </tr>
                                <tr style={{height: '51px'}}>
                                  <td>Descuentos</td>
                                  <td>{`$ ${(this.state.resume.discounts * 1).toFixed(2)}`}</td>
                                </tr>
                                <tr style={{height: '51px'}}>
                                  <td>Desc e-commerce</td>
                                  <td>{`$ ${(this.state.resume.discount_woos * 1).toFixed(2)}`}</td>
                                </tr>
                                <tr style={{height: '51px'}}>
                                  <td>Fee e-commerce</td>
                                  <td>{`$ ${(this.state.resume.woo_fees * 1).toFixed(2)}`}</td>
                                </tr>
                                <tr style={{height: '51px'}}>
                                  <td>Totales</td>
                                  <td>{`$ ${((this.state.resume.amounts - this.state.resume.discounts) * 1).toFixed(2)}`}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Invoice;
