import React from 'react'
import {CardBody, Form, FormGroup, Input, Label, Modal} from "reactstrap";

const render_rows = (tog_standard, modal_standard, new_provider_name_ref, new_provider_email_ref, new_provider_password_ref, new_note_ref, createProvider) => {

    return (
        <Modal isOpen={modal_standard}
               toggle={tog_standard}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Nuevo Proveedor
                </h5>
                <button type="button"
                        onClick={tog_standard}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <CardBody>
                    <Form>
                        <FormGroup>
                            <Label htmlFor="bundle_qty">Nombre</Label>
                            <Input autoComplete="off" type="text" className="form-control" innerRef={new_provider_name_ref} />
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup>
                            <Label htmlFor="bundle_qty">Email</Label>
                            <Input autoComplete="off" type="email" className="form-control" innerRef={new_provider_email_ref} />
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup>
                            <Label htmlFor="bundle_qty">Password</Label>
                            <Input autoComplete="off" type="text" className="form-control" innerRef={new_provider_password_ref} />
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup>
                            <Label htmlFor="bundle_qty">Notas</Label>
                            <Input
                                innerRef={new_note_ref}
                                type="textarea"
                            />
                        </FormGroup>
                    </Form>
                </CardBody>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={tog_standard}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    No
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{ backgroundColor: 'rgb(174,174,174)', borderColor: 'rgb(177,177,177)' }}
                    onClick={() => createProvider()}
                >
                    Crear
                </button>
            </div>
        </Modal>
    );
}

const ModalCreate = ({tog_standard, modal_standard, new_provider_name_ref, new_provider_email_ref, new_provider_password_ref, new_note_ref, createProvider}) => (
    render_rows(tog_standard, modal_standard, new_provider_name_ref, new_provider_email_ref, new_provider_password_ref, new_note_ref, createProvider)
)

export default ModalCreate
