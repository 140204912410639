import React from 'react'
import { } from "reactstrap";
import Dropzone from "react-dropzone";

const render_drop_zone = ({ on_drop_files, drop_file_type }) => {
    return (
        <Dropzone onDrop={ on_drop_files } >
            {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                    <div className="dz-message needsclick" style={{ padding: 6 }} {...getRootProps()} >
                        <input {...getInputProps()} />
                        <div className="dz-message needsclick" style={{ height: 200 }}>
                            <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"/>
                            </div>
                            <h4>{ drop_file_type }</h4>
                        </div>
                    </div>
                </div>
            )}
        </Dropzone>
    );
}

const render_preview = ({ images }) => {
    return (
      <div className="dropzone-previews mt-3" id="file-previews" >
          {images.map((f, i) => {
              return (
                <img key={f.name} src={f.preview} alt={f.name} className="post-slider-item img-fluid" />
              );
          })}
      </div>
    );
}

const render_url = ({ url }) => {
    return (
      <div className="dropzone-previews mt-3" id="file-previews" >
          <img src={url} alt={url} className="post-slider-item img-fluid" />
      </div>
    );
}

const DropImage = ({ on_drop_files, drop_file_type, images, col = 12, url = null}) => (
    <div className={`col-${col}`}>
        {
            render_drop_zone({ on_drop_files, drop_file_type})
        }
        {
            url ? render_url({ url }) : render_preview({ images })
        }
    </div>
)

export default DropImage
