import React, { Component } from "react"
import { Container, Col, Row, Card, CardBody  } from "reactstrap"
import {Link} from "react-router-dom";
import {apiConfig, apiPaths} from "../../../config/constants";
import mainFunctions from "../../../components/Functions";
import Request from "superagent";
import InvoiceProductsDatatable from "./datatable"
import toastr from 'toastr'
import logo from "../../../assets/images/logo-full.jpg";
import moment from "moment"
import ModalDiscount from "./modalDiscount"
import ModalClientName from "./modalClientName"
import SweetAlert from "react-bootstrap-sweetalert"

class InvoiceShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invoiceId: this.props.location.pathname.split("/")[2],
      invoice: {},
      invoice_products: [],
      print: false,
      new_discount: null,
      new_client_name: null,
      new_client_email: null,
      isLoadingBill: false,
      confirm_billed: false,
      error_billed: false,
      confirm_send_billing: false,
      success_send_billing: false,
      error_send_billing: false
    }

    this.new_category_name_ref = React.createRef();
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const print = urlParams.get('print');
    //if(print === "true") this.setState({ print: true });

    setTimeout(function() {
      this.invoice();
    }.bind(this), 20);
  }
  invoice = () => {
    const { invoiceId } = this.state;
    Request
      .get(`${apiPaths.invoices}/${invoiceId}`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        if (response.body && response.body.data) {
          const {invoice, invoice_products} = response.body.data
          this.setState({
            invoice: invoice,
            invoice_products: invoice_products,
            new_client_name: invoice.client_name,
            new_client_email: invoice.client_email
          })
          const { print } = this.state;
          if(print) this.export();
        }
      }).catch((error) => {
      this.setState({
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  };
  setPage = (value) => {
    this.setState({ page: value });
    this.invoices();
  };

  export = () => {
    const {invoiceId, export_in_process, file_url} = this.state;
    toastr.options = mainFunctions.toastrOptions();

    if(export_in_process){
      toastr["info"]("En progreso", "Exportación");
      return;
    }else if(file_url){
      window.open(`${apiConfig.baseUrl}${file_url}`);
      toastr["info"]("Si no puede bajar el archivo, tiene que dar permisos de descarga", "Exportación");
      return;
    }

    this.setState({ export_in_process: true })

    Request
      .post(`${apiPaths.invoices}/${invoiceId}/pdf`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        if (response.body && response.body.data) {
          const {file_url} = response.body.data;
          this.setState({
            file_url: file_url,
            export_in_process: false
          });
          setTimeout(() => {
            window.open(`${apiConfig.baseUrl}${file_url}`);
          }, 500);
        }
      }).catch((error) => {
        console.log(error)
        this.setState({
          dgiErrorMessage: "Ha ocurrido un error al generar la factura. Por favor, intenta nuevamente."
        })
    });
  }
  billInvoice = () => {
    console.log("billInvoice")
    const {invoiceId, isLoadingBill} = this.state;
    if (isLoadingBill) return;

    this.setState({
      isLoadingBill: true
    })
    console.log(invoiceId)

    Request
      .post(`${apiPaths.invoices}/${invoiceId}/dgi_invoice`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        console.log(response)
        if (response.body && response.body.success) {
          this.invoice()
          this.setState({
            isLoadingBill: false,
            confirm_billed: true
          }, () => {
            this.invoice()
          })
        } else if (response.body && response.body.error){
          console.log(response.body.error)
          this.setState({
            isLoadingBill: false,
            error_billed: true
          })
        } else {
          this.setState({
            isLoadingBill: false,
            error_billed: true
          })
        }
      }).catch((error) => {
      console.log(error)
        this.setState({
          isLoadingBill: false,
          error_billed: true
        })
        console.log(error)
    });
  }
  sendInvoice = () => {
    const {invoiceId} = this.state;

    Request
      .post(`${apiPaths.invoices}/${invoiceId}/send_email`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        if (response.body && response.body.success) {
          this.setState({
            success_send_billing: true
          })
        } else {
          this.setState({
            error_send_billing: true
          })
        }
      }).catch((error) => {
        this.setState({
          error_send_billing: true
        })
        console.log(error)
    });
  }
  togStandard = (attr) => {
    switch(attr) {
      case 'discount':
        this.setState({ modal_discount: !this.state.modal_discount });
        break;
      case 'client_name':
        this.setState({ modal_client_name: !this.state.modal_client_name });
        break;
      default:

    }
  };
  createOnChangeInput = (value, attr) => {
    switch(attr) {
      case 'discount':
        this.setState({new_discount: value, new_client_name: null});
        break;
      case 'discount_close':
        this.setState({new_discount: null, new_client_name: null, modal_discount: false});
        break;
      case 'update_discount':
        this.setState({new_client_name: null, modal_discount: false});
        this.update();
        break;
      case 'client_name':
        this.setState({new_discount: null, new_client_name: value});
        break;
      case 'client_email':
        this.setState({new_discount: null, new_client_email: value});
        break;
      case 'client_name_close':
        this.setState({new_discount: null, new_client_name: null, modal_client_name: false});
        break;
      case 'update_client_name':
        this.setState({new_discount: null, modal_client_name: false});
        this.update();
        break;
      default:

    }
  };

  update = () => {
    const {new_discount, new_client_name, new_client_email, invoiceId} = this.state;

    if(!new_discount && !new_client_name && !new_client_email) return;

    Request
      .put(`${apiPaths.invoices}/${invoiceId}`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send({ new_discount: new_discount, new_client_name: new_client_name, new_client_email: new_client_email})
      .then((response) => {
        window.location.reload();
      }).catch((error) => {
      console.log(error)
    });
  }
  delete = () => {
    const {invoiceId} = this.state;

    Request
      .delete(`${apiPaths.invoices}/${invoiceId}`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        window.location.assign(`/ordenes`);
      }).catch((error) => {
      console.log(error)
    });
  }
  delivered = () => {
    const {invoiceId} = this.state;

    Request
      .post(`${apiPaths.invoices}/${invoiceId}/delivered`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        window.location.reload();
      }).catch((error) => {
      console.log(error)
    });
  }
  validatePayment = () => {
    const {invoiceId} = this.state;

    toastr.options = mainFunctions.toastrOptions();
    this.setState({ confirm_validate_payment: false });
    toastr["info"]("En progreso...", "Marcando como pago");

    Request
      .post(`${apiPaths.invoices}/${invoiceId}/validate_payment`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        window.location.reload();
      }).catch((error) => {
      console.log(error)
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18"></h4>
                  <div className="page-title-right">

                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">
                        Orden { this.state.invoice.woo_invoice_id ? '#Woo' + this.state.invoice.woo_invoice_id : '#' + this.state.invoice.id }

                        <Link
                          style={{ marginLeft: 15 }}
                          to="#"
                          onClick={() => this.setState({ modal_delivered: true })}
                          className={this.state.invoice.delivered ? 'btn btn-success waves-effect waves-light me-1' : 'btn btn-danger waves-effect waves-light me-1'}
                        >
                          <i className="fa fa-truck" />
                        </Link>
                        {
                          this.state.invoice.payment_method_id === 7 ?
                            <Link
                              to="#"
                              onClick={() => this.setState({ confirm_validate_payment: true })}
                              className="btn btn-success waves-effect waves-light me-1"
                            >
                              <i className="bx bx-comment-error"/>
                            </Link>
                            :
                            null
                        }
                        <Link
                          to="#"
                          onClick={() => this.setState({ confirm_alert: true })}
                          className="btn btn-danger waves-effect waves-light me-1"
                        >
                          <i className="mdi mdi-trash-can" />
                        </Link>
                      </h4>
                      <div className="mb-4">
                        <img src={logo} alt="logo" height="40" />
                      </div>
                    </div>
                    <hr />
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        Resumen
                      </h3>
                      <Link href="#" className="bx bx-edit font-size-20 mr-2 product-new" style={{ color: 'rgb(179, 142, 132)', float: 'left' }} onClick={() => this.togStandard('client_name')}/>
                      <span style={{ display: 'block', float: 'left' }}>Cliente: {this.state.invoice.client_name}</span>
                      <ModalClientName
                        modal_standard={this.state.modal_client_name}
                        tog_standard={this.togStandard}
                        new_client_name={this.state.new_client_name}
                        new_client_email={this.state.new_client_email}
                        onChangeInput={this.createOnChangeInput}
                      />
                      <br/>
                      <span style={{ margin: '-20px'}}>Método de pago: {this.state.invoice.payment_method_name}</span>
                      <br/>
                      <span>Fecha: { moment(this.state.invoice.created_at).format('DD/MM/YYYY HH:mm')}</span>
                      <br/>
                      <span>Notas:</span>
                      {this.state.invoice.note &&
                        this.state.invoice.note.split("\n").map((item, index) => {
                          return (
                            <span><br/>{item}</span>
                          )
                        })}
                      <br/>
                      <span>Shipping:</span>
                      <br/>
                      <span>{this.state.invoice.shipping_title}</span>
                      {this.state.invoice.shipping_note &&
                        this.state.invoice.shipping_note.split("\n").map((item, index) => {
                          return (
                            <span><br/>{item}</span>
                          )
                        })}
                      {this.state.invoice.dgi_id &&
                        <span>Factura: {this.state.invoice.dgi_serie} {this.state.invoice.dgi_numero.toString().padStart(7, '0')}</span>
                      }
                    </div>
                    <div className="table-responsive">
                      <InvoiceProductsDatatable
                        invoice_products={this.state.invoice_products}
                        invoice={this.state.invoice}
                        togStandard={this.togStandard}
                      />
                      <ModalDiscount
                        modal_standard={this.state.modal_discount}
                        tog_standard={this.togStandard}
                        discount={this.state.invoice.discount}
                        onChangeInput={this.createOnChangeInput}
                      />
                    </div>
                    <div className="d-print-none">
                      {!this.state.invoice.dgi_id && !this.state.invoice.woo_invoice_id && <div className="float-end">
                        <Link
                          to="#"
                          onClick={() => {if (this.state.isLoadingBill !== true) ( this.setState({ confirm_billing: true })) }}
                          className="btn btn-success waves-effect waves-light me-1">
                          Generar factura
                        </Link>
                      </div>}
                      {this.state.invoice.dgi_id && <div className="float-end">
                        <Link
                          to="#"
                          onClick={() => {this.setState({ confirm_send_billing: true})}}
                          className="btn btn-success waves-effect waves-light me-1">
                          Enviar factura
                        </Link>
                      </div>}
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={this.export}
                          className="btn btn-success waves-effect waves-light me-1"
                        >
                          <i className="fa fa-print" />
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>


          </Container>
        </div>

        {this.state.error_send_billing ? (
          <SweetAlert
            title="Error al generar la factura"
            error
            confirmBtnText="OK"
            confirmBtnBsStyle="error"
            onConfirm={() =>
              this.setState({
                error_send_billing: false,
              })
            }
          >
            Error al generar la factura. Por favor, intente nuevamente <br/>
          </SweetAlert>
        ) : null}

        {this.state.success_send_billing ? (
          <SweetAlert
            title="Factura enviada"
            success
            confirmBtnText="OK"
            confirmBtnBsStyle="success"
            onConfirm={() =>
              this.setState({
                success_send_billing: false,
              })
            }
          >
            Factura enviada por exitosamente <br/>
          </SweetAlert>
        ) : null}
        {this.state.confirm_send_billing ? (
          <SweetAlert
            title="Reenviar factura"
            warning
            showCancel
            confirmBtnText="Confirmar"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({
                confirm_send_billing: false,
                success_dlg: this.sendInvoice(),
              })
            }
            onCancel={() =>
              this.setState({
                confirm_send_billing: false,
              })
            }
          >
            ¿Querés reenviar la factura de este pedido? <br/>
          </SweetAlert>
        ) : null}
        {this.state.error_billed ? (
          <SweetAlert
            title="Error al generar la factura"
            error
            confirmBtnText="OK"
            confirmBtnBsStyle="error"
            onConfirm={() =>
              this.setState({
                error_billed: false,
              })
            }
          >
            Error al generar la factura. Por favor, intente nuevamente <br/>
          </SweetAlert>
        ) : null}

        {this.state.confirm_billed ? (
          <SweetAlert
            title="Factura generada"
            success
            confirmBtnText="OK"
            confirmBtnBsStyle="success"
            onConfirm={() =>
              this.setState({
                confirm_billed: false,
              })
            }
          >
            Factura generada exitosamente <br/>
          </SweetAlert>
        ) : null}
        {this.state.confirm_billing ? (
          <SweetAlert
            title="Confirmar facturación"
            warning
            showCancel
            confirmBtnText="Confirmar"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({
                confirm_billing: false,
                success_dlg: this.billInvoice(),
              })
            }
            onCancel={() =>
              this.setState({
                confirm_billing: false,
              })
            }
          >
            ¿Querés generar una factura para este pedido? <br/>
          </SweetAlert>
        ) : null}
        {this.state.confirm_validate_payment ? (
          <SweetAlert
            title="Confirmar Pago"
            warning
            showCancel
            confirmBtnText="Si"
            cancelBtnText="No"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({
                confirm_alert: false,
                success_dlg: this.validatePayment(),
              })
            }
            onCancel={() =>
              this.setState({
                confirm_validate_payment: false,
              })
            }
          >
            ¡No podrás revertir esto! <br/>
          </SweetAlert>
        ) : null}
        {this.state.confirm_alert ? (
          <SweetAlert
            title="Cancelar y eliminar venta"
            warning
            showCancel
            confirmBtnText="Eliminar"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({
                confirm_alert: false,
                success_dlg: this.delete(),
              })
            }
            onCancel={() =>
              this.setState({
                confirm_alert: false,
              })
            }
          >
            ¡No podrás revertir esto! <br/>
          </SweetAlert>
        ) : null}
        {this.state.modal_delivered ? (
          <SweetAlert
            title={this.state.invoice.delivered ? "Cancelar entrega" : "Confirmar entrega"}
            warning
            showCancel
            confirmBtnText="Confirmar"
            cancelBtnText="No"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({
                modal_delivered: false,
                success_dlg: this.delivered(),
              })
            }
            onCancel={() =>
              this.setState({
                modal_delivered: false,
              })
            }
          >
          </SweetAlert>
        ) : null}
      </React.Fragment>
    )
  }
}

export default InvoiceShow;
