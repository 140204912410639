function headers(){
    return JSON.parse(localStorage.getItem("peppers_headers"))
}
function formattedGet(hash, add_user = false){
    let params = '';

    if(add_user) params += getUser();
    if(!add_user && Object.keys(hash).length > 0) params += '?';

    for(let i = 0; i < Object.keys(hash).length; i++){
        let key = Object.keys(hash)[i];
        let value = hash[key];
        if(!value) continue;
        params += `&${key}=${value}`
    }

    return params;
}
function getUser() {
    return ``;
}
function login(response){
    clearLocalStorage();

    let headers = {"uid": response.headers.uid,"client": response.headers.client,"access-token": response.headers['access-token'],"token-type": response.headers['token-type']};
    localStorage.setItem("peppers_user",  JSON.stringify(response.body.data));
    localStorage.setItem("peppers_is_admin",  JSON.stringify(response.body.data.is_admin));
    localStorage.setItem("peppers_headers", JSON.stringify(headers));
}
function logout(){
    clearLocalStorage();
    window.location.assign("/");
}
function clearLocalStorage(){
    localStorage.removeItem("peppers_user");
    localStorage.removeItem("peppers_is_admin");
    localStorage.removeItem("peppers_headers");
}
function setBrands(brands){
    let arr = [];
    brands.forEach(brand => {
        arr.push({ id: brand.id, name: brand.name });
    });

    localStorage.removeItem("peppers_current_brands")
    localStorage.setItem("peppers_current_brands", JSON.stringify(arr));
}
function setCategories(categories){
    let arr = [];
    categories.forEach(category => {
        arr.push({ id: category.id, name: category.name });
    });

    localStorage.removeItem("peppers_current_categories")
    localStorage.setItem("peppers_current_categories", JSON.stringify(arr));
}
function setSizes(sizes){
    let arr = [];
    sizes.forEach(size => {
        arr.push({ id: size.id, name: size.name });
    });

    localStorage.removeItem("peppers_current_sizes")
    localStorage.setItem("peppers_current_sizes", JSON.stringify(arr));
}
function setColors(colors){
    let arr = [];
    colors.forEach(color => {
        arr.push({ id: color.id, name: color.name });
    });

    localStorage.removeItem("peppers_current_colors")
    localStorage.setItem("peppers_current_colors", JSON.stringify(arr));
}
function setPaymentMethods(payment_methods){
    let arr = [];
    payment_methods.forEach(payment_method => {
        arr.push({ id: payment_method.id, name: payment_method.name });
    });

    localStorage.setItem("peppers_current_payment_methods", JSON.stringify(arr));
}
function setUsers(users){
    let arr = [];
    users.forEach(user => {
        arr.push({ id: user.id, name: user.name });
    });

    localStorage.setItem("peppers_current_users", JSON.stringify(arr));
}
function setProviders(providers){
    let arr = [];
    providers.forEach(provider => {
        arr.push({ id: provider.id, name: provider.name });
    });

    localStorage.removeItem("peppers_current_providers")
    localStorage.setItem("peppers_current_providers", JSON.stringify(arr));
}
function getBrands(){
    if(!localStorage.getItem("peppers_current_brands")) return [{ label: 'Ninguna', value: null }];
    const brands = JSON.parse(localStorage.getItem("peppers_current_brands"));

    let items = [{ label: 'Ninguna', value: null }];
    brands.forEach(brand => { items.push({ label: brand.name, value: brand.id }); });
    return items;
}
function getCCategories(){
    if(!localStorage.getItem("peppers_current_categories")) return [{ label: 'Ninguna', value: null }];
    const categories = JSON.parse(localStorage.getItem("peppers_current_categories"));

    let items = [{ label: 'Ninguna', value: null }];
    categories.forEach(category => { items.push({ label: category.name, value: category.id }); });
    return items;
}
function getSizes(){
    if(!localStorage.getItem("peppers_current_sizes")) return [{ label: 'Ninguna', value: null }];
    const sizes = JSON.parse(localStorage.getItem("peppers_current_sizes"));

    let items = [{ label: 'Ninguna', value: null }];
    sizes.forEach(size => { items.push({ label: size.name, value: size.id }); });
    return items;
}
function getColors(){
    if(!localStorage.getItem("peppers_current_colors")) return [{ label: 'Ninguna', value: null }];
    const colors = JSON.parse(localStorage.getItem("peppers_current_colors"));

    let items = [{ label: 'Ninguna', value: null }];
    colors.forEach(color => { items.push({ label: color.name, value: color.id }); });
    return items;
}
function getPaymentMethods(){
    if(!localStorage.getItem("peppers_current_payment_methods")) return [{ label: 'Todas', value: null }];
    const payment_methods = JSON.parse(localStorage.getItem("peppers_current_payment_methods"));

    let items = [];
    payment_methods.forEach(payment_method => { items.push({ label: payment_method.name, value: payment_method.id }); });
    return items;
}
function getUsers(){
    if(!localStorage.getItem("peppers_current_users")) return [{ label: 'Todos', value: null }];
    const users = JSON.parse(localStorage.getItem("peppers_current_users"));

    let items = [{ label: 'Todos', value: null }];
    users.forEach(user => { items.push({ label: user.name, value: user.id }); });
    return items;
}
function getProviders(){
    if(!localStorage.getItem("peppers_current_providers")) return [{ label: 'Todos', value: null }];
    const providers = JSON.parse(localStorage.getItem("peppers_current_providers"));

    let items = [{ label: 'Todos', value: null }];
    providers.forEach(provider => { items.push({ label: provider.name, value: provider.id }); });
    return items;
}
function imAdmin(){
    if(!localStorage.getItem("peppers_is_admin")) return false;

    return JSON.parse(localStorage.getItem("peppers_is_admin"));
}
function getUserName(){
    if(!localStorage.getItem("peppers_user")) return 'Anónimo';

    try { return JSON.parse(localStorage.getItem("peppers_user")).name; }
    catch(err) { return 'Anónimo'; }
}
function toastrOptions(){
    return {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }
}
export default {
    headers,
    formattedGet,
    login,
    logout,
    setBrands,
    setCategories,
    setSizes,
    setColors,
    setPaymentMethods,
    setUsers,
    setProviders,
    getBrands,
    getCCategories,
    getSizes,
    getColors,
    getPaymentMethods,
    getUsers,
    getProviders,
    imAdmin,
    getUserName,
    toastrOptions
}