import React from 'react'
import {Modal} from "reactstrap";

const render_rows = (tog_standard, modal_standard, category, deleteCurrentCategory) => {
    if(!category) return "";

    const { name } = category;

    return (
        <Modal
            size="xl"
            isOpen={modal_standard}
            toggle={tog_standard}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Eliminar categoria { name }
                </h5>
                <button type="button"
                        onClick={tog_standard}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={tog_standard}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    onClick={deleteCurrentCategory}
                    className="btn btn-primary waves-effect waves-light"
                    data-dismiss="modal"
                >
                    Eliminar
                </button>
            </div>
        </Modal>
    );
}

const ModalDeleteCategory = ({tog_standard, modal_standard, category, deleteCurrentCategory}) => (
    render_rows(tog_standard, modal_standard, category, deleteCurrentCategory)
)

export default ModalDeleteCategory
