import React from 'react'
import {Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import logo from "../../assets/images/logo-full.jpg";
import './products.scss';

const text_amount = (amount) => {
  return `$ ${(amount * 1).toFixed(2)}`
}
const product_url = (image_url) => {
  return image_url? image_url : logo;
}
const render_rows = (products, openModal, print, imAdmin ) => {
  if(!products) return "";

  const renderRow = (product) => (
    <tr key={"product_" + product.id}>
      <td>
        <div
          className="img-background"
          style={{backgroundImage: `url(${product_url(product.image_url)})`}}
        />
      </td>
      <td style={{ whiteSpace: "normal" }}>
        <h5 className="font-size-14 text-truncate" style={{whiteSpace: 'normal'}}>{product.name}</h5>
        {product.sku && <p className="mb-0" style={{whiteSpace: 'normal'}}>SKU: <span className="font-weight-medium">{product.sku}</span></p>}
        <p className="mb-0" style={{whiteSpace: 'normal'}}>Código: <span className="font-weight-medium">{product.code}</span></p>
        {product.provider_name && <p className="mb-0" style={{whiteSpace: 'normal'}}>Proveedor: <span className="font-weight-medium">{product.provider_name}</span></p>}
      </td>
      <td style={{ whiteSpace: "normal" }}>
        {product.color && <p className="mb-0" style={{whiteSpace: 'normal'}}>Color: <span className="font-weight-medium">{product.color}</span></p>}
        {product.size && <p className="mb-0" style={{whiteSpace: 'normal'}}>Talle: <span className="font-weight-medium">{product.size}</span></p>}
        {product.category_name && <p className="mb-0" style={{whiteSpace: 'normal'}}>Categoría: <span className="font-weight-medium">{product.category_name}</span></p>}
        {product.brand_name && <p className="mb-0" style={{whiteSpace: 'normal'}}>Marca: <span className="font-weight-medium">{product.brand_name}</span></p>}
      </td>
      <td style={{ whiteSpace: "normal" }}>
        <h5 className="font-size-14 text-truncate">{text_amount(product.price)}</h5>
      </td>
      <td className="ddisable" style={{ whiteSpace: "normal" }}>
        {product.description}
      </td>
      {
        imAdmin ?
          <td style={{ whiteSpace: "normal", minWidth: '72px' }}>
            <Link to="#" className="action-icon text-danger icon-margin" onClick={() => openModal(product, 'update')}> <i className="bx bx-edit font-size-22"/></Link>
            <Link to="#" className="action-icon text-danger icon-margin" onClick={() => openModal(product, 'delete')}> <i className="mdi mdi-trash-can font-size-22"/></Link>
            <Link to="#" className="action-icon text-danger icon-margin" onClick={() => print(product)}> <i className="mdi mdi-printer font-size-22"/></Link>
          </td>
          :
          ''
      }
    </tr>
  )
  return products.map(renderRow)
}
const ProductsDatatable = ({ products, openModal, print, imAdmin }) => (
  <Card>
    <CardBody>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="thead-light">
          <tr>
            <th/>
            <th/>
            <th/>
            <th/>
            <th className="ddisable"/>
            {
              imAdmin ?
                <th style={{ whiteSpace: "normal", minWidth: '72px' }}/>
                :
                ''
            }
          </tr>
          </thead>
          <tbody>
          { render_rows(products, openModal, print, imAdmin) }
          </tbody>
        </table>
      </div>
    </CardBody>
  </Card>
)

export default ProductsDatatable
