import React from "react"
import { Redirect } from "react-router-dom"

//dashboard
import Category from '../pages/Category/index'
import Brand from '../pages/Brand/index'
import Product from '../pages/Product/index'
import Sell from '../pages/Sell/index'
import Invoice from '../pages/Invoice/index'
import InvoiceShow from '../pages/Invoice/Show/index'
import Provider from '../pages/Provider/index'
import Sold from '../pages/Sold/index'

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

const authProtectedRoutes = [
  { path: "/ordenes", component: Invoice },
  { path: "/categorias", component: Category },
  { path: "/marcas", component: Brand },
  { path: "/productos", component: Product },
  { path: "/vendidos", component: Sold },
  { path: "/venta", component: Sell },
  { path: "/orden/:id", component: InvoiceShow },
  { path: "/proveedores", component: Provider },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/ordenes" /> }
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
