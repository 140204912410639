import React, { Component } from 'react';

import { Row, Col, CardBody, Card, Alert,Container } from "reactstrap";
import Request from "superagent";

import {apiPaths} from "../../config/constants";

import { withRouter, Link } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';

import logo from "../../assets/images/logo-full.jpg";
import mainFunctions from "../../components/Functions";

//document.body.style = 'background: #b38e84';

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: undefined
    };

    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.errorMessage = "";

    document.body.style = 'background: #000000';
  }

  login = () => {
    this.setState({
      error: undefined
    });

    if (this.emailRef.current.value && this.passwordRef.current.value) {
      Request
        .post(apiPaths.login)
        .set('Accept', 'application/json')
        .send({ email: this.emailRef.current.value, password: this.passwordRef.current.value })
        .then((response) => {
          console.log("repsonse")
          console.log(response)
          console.log(response.body)
          console.log(response.body.data)
          if (response.body && response.body.data && response.headers) {
            mainFunctions.login(response);
            this.data();
          } else {
            this.errorMessage = "Error del servidor. Por favor, intente nuevamente luego.";
            this.setState({})
          }
        }).catch((error) => {
        console.log("error")
        console.log(error)
        if (error && error.status && error.status === 401) {
          this.errorMessage = "No existe una cuenta con ese correo electrónico o la contraseña es incorrecta.";
          this.setState({})
        } else if (error && error.status && error.status === 500) {
          this.errorMessage = "Error del servidor. Por favor, intente nuevamente luego.";
          this.setState({})
        }
      });
    } else {
      this.setState({
        error: "Los campos no pueden estar vacíos"
      })
    }
  };

  data = () => {
    Request
      .post(`${apiPaths.users}/data`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .then((response) => {
        if (response.body && response.body.data) {
          const { brands, categories, sizes, colors, payment_methods, users, providers } = response.body.data;
          mainFunctions.setBrands(brands);
          mainFunctions.setCategories(categories);
          mainFunctions.setSizes(sizes);
          mainFunctions.setColors(colors);
          mainFunctions.setPaymentMethods(payment_methods);
          mainFunctions.setUsers(users);
          mainFunctions.setProviders(providers);
          window.location.assign("/productos");
        }
      }).catch((error) => {
      this.setState({
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  };

  render() {

    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={7} lg={6} xl={5}>
                <div className="text-center">
                  <img src={logo} alt="" className="img-fluid" style={{ width: 310, marginTop: 30, marginBottom: 20 }} />
                </div>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <div className="p-2">

                      <AvForm className="form-horizontal" onValidSubmit={this.login}>

                        {this.state.error && this.state.error ? <Alert color="danger">{this.props.error}</Alert> : null}

                        <div className="form-group" style={{ marginTop: 20 }}>
                          <AvField name="email" label="E-mail" innerRef={this.emailRef}  className="form-control" placeholder="E-mail" type="email" required />
                        </div>

                        <div className="form-group mt-3">
                          <AvField name="password" label="Contraseña" innerRef={this.passwordRef}  type="password" required placeholder="Contraseña" />
                        </div>

                        <div className="mt-4">
                          <button className="btn btn-primary btn-block waves-effect waves-light" type="submit" style={{ width: '100%', backgroundColor: 'rgb(174,174,174)', borderColor: 'rgb(177,177,177)' }}>Ingresar</button>
                        </div>

                        {/*<div className="mt-4 text-center">
                          <Link to="/password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> ¿Olvidaste tu contraseña?</Link>
                        </div>*/}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="text-center" style={{ height: 40 }}>
                  {<div style={{ color: '#e8e2e2' }}>{this.errorMessage}</div>}
                </div>
                <div className="text-center" style={{ marginTop: 30 }}>
                  <p style={{ color: '#f1d3cb' }}>© {new Date().getFullYear()} Twire</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);

