import React from 'react'
import {Card, CardBody, Tooltip} from "reactstrap";
import {Link} from "react-router-dom";

const render_rows = (providers, open_provider_modal, delete_modal ) => {
    if(!providers) return "";

    const renderRow = (provider) => (
        <tr key={"product_" + provider.id}>
            <td style={{ whiteSpace: "normal" }}>
                {provider.name}
            </td>
            <td style={{ whiteSpace: "normal" }}>
                {provider.email}
            </td>
            <td style={{ whiteSpace: "normal" }}>
                {provider.product_not_paid_yet}
            </td>
            <td className="ddisable" style={{ whiteSpace: "normal" }}>
                {textPaymentMethod(provider.payment_method)}
            </td>
            <td className="ddisable" style={{ whiteSpace: "normal" }}>
                {provider.note}
            </td>
            <td style={{ whiteSpace: "normal" }}>
                <Link to="#" className="action-icon text-danger icon-margin" onClick={() => open_provider_modal(provider)}> <i className="bx bx-edit font-size-22"/></Link>
                <Link to="#" className="action-icon text-danger icon-margin" onClick={() => delete_modal(provider)}> <i className="mdi mdi-trash-can font-size-22"/></Link>
            </td>
        </tr>
    )
    return providers.map(renderRow)
}
const textPaymentMethod = (payment_method) => {
    if(payment_method === 'bank_draft') return 'Giro bancario';
    else if(payment_method === 'cash') return 'Efectivo';
    else return 'No elegido';
}
const ProvidersDatatable = ({ providers, open_provider_modal, delete_modal }) => (
    <Card>
        <CardBody>
            <div className="table-responsive" style={{overflowY: 'hidden'}}>
                <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                    <tr>
                        <th>Proveedor</th>
                        <th>Email</th>
                        <th>Sin cobrar</th>
                        <th>Metodo de cobro</th>
                        <th>Notas</th>
                        <th style={{ width: 100 }}/>
                    </tr>
                    </thead>
                    <tbody>
                    { render_rows(providers, open_provider_modal, delete_modal) }
                    </tbody>
                </table>
            </div>
        </CardBody>
    </Card>
)

export default ProvidersDatatable
