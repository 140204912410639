import PropTypes from 'prop-types'
import React, { Component } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

import { Link } from "react-router-dom"

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

//import logo from "../../assets/images/logo.svg"
import logoLightPng from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
//import logoDark from "../../assets/images/logo-dark.png"

import logo from "../../assets/images/logo-full.jpg";

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import { toggleRightSidebar } from "../../store/actions"
import mainFunctions from "../Functions"
import Select from "react-select"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback()
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/productos" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22"/>
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="100"/>
                  </span>
                </Link>

                <Link to="/productos" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22"/>
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="70"/>
                  </span>
                </Link>
              </div>

              {<button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>}
            </div>
            <div className="d-flex" >
              <ProfileMenu />

            </div>
          </div>
        </header>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
)

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  toggleRightSidebar: PropTypes.func
}