import React from 'react'
import { Input, Modal, Row, Col, Form, FormGroup, Label, CardBody } from "reactstrap"

const render_rows = (tog_standard, modal_standard, provider, onChangeInput, updateCurrentProvider) => {
    if(!provider) return "";

    const { name, email, note } = provider;
    return (
        <Modal
            isOpen={modal_standard}
            toggle={tog_standard}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Actualizar proveedor { name }
                </h5>
                <button type="button"
                        onClick={tog_standard}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Form>
                    <FormGroup>
                        <Label htmlFor="name">Nombre</Label>
                        <Input
                            type="text"
                            className="form-control"
                            defaultValue={ name }
                            onChange={ e => {onChangeInput( e.target.value, 'name')} }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="name">Email</Label>
                        <Input
                            type="text"
                            className="form-control"
                            defaultValue={ email }
                            onChange={ e => {onChangeInput( e.target.value, 'email')} }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="name">Nueva Password</Label>
                        <Input
                            type="text"
                            className="form-control"
                            onChange={ e => {onChangeInput( e.target.value, 'password')} }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="bundle_qty">Notas</Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          defaultValue={ note }
                          onChange={ e => {onChangeInput( e.target.value, 'note')} }
                        />
                    </FormGroup>
                </Form>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={tog_standard}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancelar
                </button>
                <button
                    type="button"
                    onClick={updateCurrentProvider}
                    className="btn btn-primary waves-effect waves-light"
                    data-dismiss="modal"
                >
                    Actualizar
                </button>
            </div>
        </Modal>
    );
}

const ModalUpdateProvider = ({tog_standard, modal_standard, provider, onChangeInput, updateCurrentProvider}) => (
    render_rows(tog_standard, modal_standard, provider, onChangeInput, updateCurrentProvider)
)

export default ModalUpdateProvider
