import React, { Component } from "react"
import { Container, Col, Row, Card, CardBody  } from "reactstrap"
import {Link} from "react-router-dom";
import ModalCreate from "./modalCreate"
import {apiPaths} from "../../config/constants";
import mainFunctions from "../../components/Functions";
import Request from "superagent";
import CategoriesDatatable from "./datatable"
import toastr from 'toastr'
import Pages from "../../components/Pages"
import ModalUpdateCategory from "./ModalUpdateCategory"
import ModalDeleteCategory from "./ModalDeleteCategory"

class Category extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      page_size: 50,
      total_rows: 0,
      q: '',
    }

    this.new_category_name_ref = React.createRef();
  }
  componentDidMount() {
    this.categories();
  }
  categories = () => {
    setTimeout(function() {
      const { page, page_size, q } = this.state;
      let query = { page: page, pageSize: page_size, q: q }

      Request
        .get(apiPaths.categories + mainFunctions.formattedGet(query, false))
        .set('Accept', 'application/json')
        .set(mainFunctions.headers())
        .then((response) => {
          if (response.body && response.body.data) {
            let data = response.body.data;
            this.setState({
              categories: data.categories,
              total_rows: data.total_rows,
            });
          }
        }).catch((error) => {
        this.setState({
          errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
        })
      });
    }.bind(this), 20);
  };
  togCreateCategory = () => {
    this.setState({ modal_create_category: !this.state.modal_create_category });
  }
  createCategory = () => {
    const name = this.new_category_name_ref.current.value
    if(!name || name.length === 0 ) return;

    this.setState({ modal_create_category: false });
    let post = { name: name };
    toastr.options = mainFunctions.toastrOptions();

    Request
      .post(apiPaths.categories)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send(post)
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          const { categories } = response.body;
          mainFunctions.setCategories(categories);
          this.categories();
        } else {
          toastr["warning"]("Error inesperado", "Categoria");
        }
        
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  showCategoryModal = (category) =>{
    this.setState({ current_category: category });
    this.tog_standard();
  };
  tog_standard = () => {
    this.setState({ modal_standard: !this.state.modal_standard });
  };
  deleteCategoryModal = (category) =>{
    this.setState({ current_category: category });
    this.tog_delete();
  };
  tog_delete = () => {
    this.setState({ modal_delete: !this.state.modal_delete });
  };
  setPage = (value) => {
    this.setState({ page: value });
    this.categories();
  };

  updateCurrentCategoryInput = (value) => {
    const {current_category} = this.state;
    current_category.name = value;
  };
  updateCurrentCategory = () => {
    const { current_category } = this.state;
    const { id, name } = current_category;

    const cat = {
      name: name
    };

    toastr.options = mainFunctions.toastrOptions();
    Request
      .put(`${apiPaths.categories}/${id}`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send(cat)
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          this.setState({ modal_standard: false });
          const { categories } = response.body;
          mainFunctions.setCategories(categories);
          this.categories();
        } else {
          toastr["warning"]("Error inesperado", "Categoria");
        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }
  deleteCurrentCategory = () => {
    const { current_category } = this.state;
    const { id } = current_category;
    toastr.options = mainFunctions.toastrOptions();

    Request
      .delete(`${apiPaths.categories}/${id}`)
      .set('Accept', 'application/json')
      .set(mainFunctions.headers())
      .send({})
      .then((response) => {
        if(response.status >= 200 && response.status <= 299) {
          const { categories } = response.body;
          mainFunctions.setCategories(categories);
          this.setState({ modal_delete: false });
          this.categories();
        } else {
          toastr["warning"]("Error inesperado", "Categoria");
        }
      }).catch((error) => {
      this.setState({
        isSending: false,
        errorMessage: "Ha ocurrido un error en el servidor. Por favor, intenta nuevamente luego."
      })
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">Categorias</h4>
                  <div className="page-title-right">
                    <Link href="#" className="bx bx-plus-circle font-size-24 mr-2 category-new" style={{ color: '#495057' }}  onClick={() => this.togCreateCategory()}/>
                    <ModalCreate
                      modal_standard={this.state.modal_create_category}
                      tog_standard={() => this.togCreateCategory()}
                      new_category_name_ref={this.new_category_name_ref}
                      createCategory={() => this.createCategory()}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CategoriesDatatable
                      categories={this.state.categories}
                      open_category_modal={(category) => this.showCategoryModal(category)}
                      delete_modal={(category) => this.deleteCategoryModal(category)}
                    />
                    <Pages
                      page={this.state.page}
                      page_size={this.state.page_size}
                      total_rows={this.state.total_rows}
                      onClickPage={value => this.setPage(value)}
                    />
                    <ModalUpdateCategory
                      modal_standard={this.state.modal_standard}
                      tog_standard={() => this.tog_standard()}
                      category={this.state.current_category}
                      onChangeInput={(value) => this.updateCurrentCategoryInput(value)}
                      updateCurrentCategory={this.updateCurrentCategory}
                    />
                    <ModalDeleteCategory
                      modal_standard={this.state.modal_delete}
                      tog_standard={() => this.tog_delete()}
                      category={this.state.current_category}
                      deleteCurrentCategory={this.deleteCurrentCategory}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Category;
